// @ts-nocheck
import { mat4 } from "gl-matrix";
import REGL from "regl/dist/regl.js";

import Colormap from "./colormap.ts";
import { SHADER_SOURCE } from "./shader-source.ts";

class Drawer {
    private regl: REGL.Regl;
    private colormap: Colormap;
    private terrain: number;
    constructor(regl: REGL.Regl) {
        this.regl = regl;
        this.colormap = new Colormap();
    }

    public setTerrain(terrain: number): void {
        this.colormap.setTerrain(terrain);
        this.terrain = terrain;
    }

    public getTerrain(): number {
        return this.terrain;
    }

    public drawRivers(fbo_river: REGL.Framebuffer, river_texturemap: REGL.Texture, props: any) {
        return this.regl({
            ...SHADER_SOURCE.rivers,
            uniforms: {
                u_projection: this.regl.prop("u_projection"),
                u_rivertexturemap: river_texturemap,
            },
            //定义渲染目标，可用作纹理，允许多次渲染。
            framebuffer: fbo_river,
            //启用混合模式，支持透明度控制
            blend: {
                enable: true,
                func: { src: "one", dst: "one minus src alpha" },
                equation: {
                    rgb: "add",
                    alpha: "add",
                },
                color: [1, 1, 1, 0],
            },
            //配置深度测试，确保物体的正确遮挡关系。
            depth: {
                enable: false,
            },
            count: this.regl.prop("count"),
            //指定每个顶点的属性，如位置和法线等。
            attributes: {
                a_xyuv: this.regl.prop("a_xyuv"),
            },
        })(props);
    }

    public drawLand(fbo_land: REGL.Framebuffer, props: any) {
        return this.regl({
            ...SHADER_SOURCE.land,
            uniforms: {
                u_projection: this.regl.prop("u_projection"),
                u_water: this.regl.prop("u_water"),
                u_outline_water: this.regl.prop("u_outline_water"),
                u_m: this.regl.prop("u_m"),
                export_value: this.regl.prop("export_value"),
            },

            framebuffer: fbo_land,
            depth: {
                enable: false,
            },
            elements: this.regl.prop("elements"),
            //@ts-ignore
            attributes: {
                a_xy: this.regl.prop("a_xy"),
                a_em: this.regl.prop("a_em"),
            },
        })(props);
    }

    public drawDepth(fbo_depth: REGL.Framebuffer, props: any) {
        return this.regl({
            ...SHADER_SOURCE.depth,
            framebuffer: fbo_depth,
            elements: this.regl.prop("elements"),
            attributes: {
                a_xy: this.regl.prop("a_xy"),
                a_em: this.regl.prop("a_em"),
            },
            uniforms: {
                u_projection: this.regl.prop("u_projection"),
            },
        })(props);
    }

    public drawDrape(
        fbo_final: REGL.Framebuffer,
        u_land_texture: REGL.Texture,
        texture_size: number,
        props: any,
    ) {
        const colormapData = this.colormap.getColormap();

        return this.regl({
            ...SHADER_SOURCE.drape,

            framebuffer: fbo_final,
            elements: this.regl.prop("elements"),
            attributes: {
                a_xy: this.regl.prop("a_xy"),
                a_em: this.regl.prop("a_em"),
            },
            uniforms: {
                u_projection: this.regl.prop("u_projection"),
                u_depth: this.regl.prop("u_depth"),
                u_colormap: this.regl.texture({
                    width: colormapData.width,
                    height: colormapData.height,
                    data: colormapData.color,
                    wrapS: "clamp",
                    wrapT: "clamp",
                }),
                u_mapdata: () => u_land_texture,
                u_water: this.regl.prop("u_water"),
                u_inverse_texture_size: 1.5 / texture_size,
                u_light_angle: this.regl.prop("u_light_angle"),
                u_slope: this.regl.prop("u_slope"),
                u_flat: this.regl.prop("u_flat"),
                u_ambient: this.regl.prop("u_ambient"),
                u_overhead: this.regl.prop("u_overhead"),
                u_outline_depth: this.regl.prop("u_outline_depth"),
                u_outline_coast: this.regl.prop("u_outline_coast"),
                u_outline_water: this.regl.prop("u_outline_water"),
                u_outline_strength: this.regl.prop("u_outline_strength"),
                u_outline_threshold: this.regl.prop("u_outline_threshold"),
                u_biome_colors: this.regl.prop("u_biome_colors"),
            },
        })(props);
    }

    public drawFinal(textures: any, props: any) {
        return this.regl({
            ...SHADER_SOURCE.final,
            uniforms: {
                u_land_texture: textures.land,
                u_final_texture: textures.final,
                u_river_texture: textures.river,
                u_offset: this.regl.prop("u_offset"),
                export_value: this.regl.prop("export_value"),
            },
            depth: {
                enable: false,
            },
            count: 3,
            attributes: {
                a_uv: [-2, 0, 0, -2, 2, 2],
            },
        })(props);
    }

    public drawImage(params: {
        projection: mat4;
        fbo: REGL.Framebuffer2D;
        image: HTMLImageElement;
        x: number;
        y: number;
        width: number;
        height: number;
    }) {
        const { x, y, width, height, image } = params;
        const position = [
            x,
            y,
            x,
            height + y,
            width + x,
            y,
            x + width,
            y,
            x,
            y + height,
            x + width,
            y + height,
        ];
        const texture = this.regl.texture({
            data: image,
            width,
            height,
            flipY: true,
        });
        return this.regl({
            ...SHADER_SOURCE.icons,
            framebuffer: this.regl.prop("fbo"),
            uniforms: {
                u_texture: this.regl.prop("texture"),
                u_projection: this.regl.prop("projection"),
            },
            attributes: {
                // Put a unit quad in the buffer with scale and transform
                a_position: this.regl.prop("position"),
                // a_position: [0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1],
                a_texcoord: [0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1],
            },
            count: 6,
            // primitive: "triangles",
            blend: {
                enable: true,
                func: {
                    srcRGB: "src alpha",
                    srcAlpha: 1,
                    dstRGB: "one minus src alpha",
                    dstAlpha: 1,
                },
            },
            depth: {
                enable: false,
                // mask: false,
            },
        })({ ...params, position, texture });
    }

    public drawIcons(props: any) {
        return this.regl({
            ...SHADER_SOURCE.icons,
            framebuffer: props.fbos,
            attributes: {
                a_position: (_, props) => {
                    const positions = [];
                    for (const icon of props.icons) {
                        // 为每个图标创建两个三角形（6个顶点）
                        for (let i = 0; i < 6; i++) {
                            positions.push(icon.position);
                        }
                    }
                    return positions;
                },
                a_texcoord: {
                    buffer: this.regl.buffer(
                        new Float32Array([
                            0,
                            0, // 第一个三角形
                            1,
                            0,
                            0,
                            1,
                            1,
                            0, // 第二个三角形
                            1,
                            1,
                            0,
                            1,
                        ]),
                    ),
                    divisor: 0,
                },
            },
            uniforms: {
                u_projection: this.regl.prop("u_projection"),
                u_icon_size: this.regl.prop("u_icon_size"),
                u_icon: this.regl.prop("u_icon"),
            },
            blend: {
                enable: true,
                func: {
                    srcRGB: "src alpha",
                    srcAlpha: 1,
                    dstRGB: "one minus src alpha",
                    dstAlpha: 1,
                },
            },
            depth: {
                enable: true,
                mask: false,
            },
            viewport: this.regl.prop("viewport"),
            count: (_, props) => props.icons.length * 6,
            primitive: "triangles",
        })(props);
    }
}

export default Drawer;
