import { Outlet, useLocation } from "react-router-dom";
import Layout from "../Layout/Layout";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

export default function LayoutRender() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
          hitType: "pageview",
          page: location.pathname,
          title: document.title
        });
      }, [location]);

    return (
        <main className="h-screen flex flex-col">
            <Layout>
                <Outlet />
            </Layout>
        </main>
    )
}