import { Tooltip } from "antd";

interface OptionType {
    value: string;
    tooltip: string;
}

interface TagGroupProps {
    label: string;
    options: OptionType[] | string[];
    selectedOptions: string[];
    selectedRadio?: string;
    isLoading?: boolean;
    radioOptions: OptionType[];
    onRadioChange?: (value: string) => void;
    onOptionChange?: (option: string, checked: boolean) => void;
    disabled?: boolean;
    disabledOptions?: string[];
}

const TagGroup = ({
    label,
    options,
    selectedOptions,
    selectedRadio = '',
    isLoading = false,
    radioOptions,
    onRadioChange,
    onOptionChange,
    disabled = false,
    disabledOptions = [],
}: TagGroupProps) => {
    return (
        <div className="mb-5">
            <div className="text-white opacity-50 mt-3">{label}</div>
            {onRadioChange && (
                <>
                    {radioOptions.map((option) => (
                        <Tooltip key={option.value} title={option.tooltip}>
                            <div
                                className={`
                                border rounded-md px-3 py-1 mb-2 inline-flex items-center mr-2
                                ${(isLoading || disabled) ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
                                ${selectedRadio === option.value
                                    ? 'border-white bg-gradient-to-r from-[#345777] to-[#763BAF] text-white'
                                    : 'border-gray-500 text-gray-400'
                                }
                            `}
                                onClick={() => {
                                    if (!isLoading && !disabled) {
                                        onRadioChange(selectedRadio === option.value ? '' : option.value);
                                    }
                                }}
                            >
                                <div
                                    className={`
                                    w-3 h-3 rounded-full border-2 mr-2
                                    ${selectedRadio === option.value
                                        ? 'border-white border-4'
                                        : 'border-gray-400 bg-transparent'
                                    }
                                `}
                                />
                                <span>{option.value}</span>
                            </div>
                        </Tooltip>
                    ))}
                </>
            )}
            <div className="h-6 w-px bg-white opacity-30 mx-2 inline-flex items-center align-middle" />
            {options.map((option) => {
                const optionValue = typeof option === 'string' ? option : option.value;
                const tooltip = typeof option === 'string' ? '' : option.tooltip;

                return (
                    <Tooltip key={optionValue} title={tooltip}>
                        <div
                            className={`
                            border rounded-md px-3 py-1 mr-2 mb-2 inline-flex items-center
                            ${(isLoading || disabled || disabledOptions.includes(optionValue)) ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
                            ${selectedOptions.includes(optionValue)
                                ? 'border-white bg-gradient-to-r from-[#345777] to-[#763BAF] text-white'
                                : 'border-gray-500 text-gray-400'
                            }
                        `}
                            onClick={() => {
                                if (!isLoading && !disabled && !disabledOptions.includes(optionValue)) {
                                    onOptionChange?.(optionValue, !selectedOptions.includes(optionValue));
                                }
                            }}
                        >
                            <div
                                className={`
                                w-3 h-3 border-2 mr-2 relative flex items-center justify-center
                                ${selectedOptions.includes(optionValue)
                                        ? 'border-white'
                                        : 'border-gray-400'
                                    }
                            `}
                            >
                                {selectedOptions.includes(optionValue) && (
                                    <svg
                                        className="w-3 h-3 text-white"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            d="M4 10l4 4L16 6"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )}
                            </div>
                            <span>{optionValue}</span>
                        </div>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export default TagGroup;