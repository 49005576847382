import { useRef, useState } from 'react';
import { message } from "antd";
import { Link } from "react-router-dom";
import backIcon from "@/assets/back.png";
import VillageTagSelector from "@/components/VillageTagSelector";

import { generateUSDZ, getVillageLayout } from "@/service/taskService";
import GenerateBtn from '@/components/GenerateBtn';
import { useFullscreen } from "ahooks";
import fullScreenExitIcon from "@/assets/fullscreen_exit.svg";
import fullScreenIcon from "@/assets/fullscreen.svg";

function LabTagToVillageLayout() {
    const [isLoading, setIsLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [generateLoading, setGenerateLoading] = useState(false);
    const mapjson = useRef(null);
    const imageRef = useRef(null);
    const [isFullscreen, { toggleFullscreen }] = useFullscreen(imageRef);
    const [usdzResult, setUsdzResult] = useState<string[]>([]);
    // const [show3D, setShow3D] = useState(false);

    const generateRoadHandler = async (data: any) => {
        mapjson.current = null;
        usdzResult && setUsdzResult([]);
        setIsLoading(true);
        try {
            // 收集所有标签到一个数组中
            const tags: string[] = [];

            // 处理 road
            if (data.road) {
                if (data.road.radio) tags.push(data.road.radio);
                if (data.road.tags) tags.push(...data.road.tags);
            }

            // 处理简单字段
            if (data.water) tags.push(data.water);
            if (data.villageDensity) tags.push(data.villageDensity);
            if (data.villageShape) tags.push(data.villageShape);

            // 处理 farmland
            if (data.farmland) {
                if (data.farmland.radio) tags.push(data.farmland.radio);
                if (data.farmland.tags) tags.push(...data.farmland.tags);
            }
            const response = await getVillageLayout(tags.join(','), data.mapSize);
            mapjson.current = response.data.mapjson;
            setImageUrl(response.data.image);
        } catch (error) {
            message.error(error as string);
        } finally {
            setIsLoading(false);
        }
    };

    const generateUSDZFile = async (geojson: any) => {
        const generateData = {
            "map_size": 40,
            "upload_usdz": true,
            "upload_config": {
                "name_space": "cybever",
                "project_name": "village",
                "target_path": `layout/demo${new Date().getTime()}.usdc`
            },
            "geojson_content": JSON.stringify(geojson)
        }
        const result = await generateUSDZ(generateData);
        return result.data;
    };


    const generate3DHandler = async () => {
        if (!mapjson.current) {
            message.error("Please generate layout first");
            return;
        }

        setGenerateLoading(true);
        try {
            const result = await generateUSDZFile(mapjson.current);
            console.log({result});
            
            setUsdzResult(result);
            // setShow3D(true);
        } catch (error) {
            message.error(error as string);
        } finally {
            setGenerateLoading(false);
        }
    }


    return (
        <div className='h-full flex flex-col'>
            <div className="flex items-center pb-5">
                <Link to="/labs" className="flex items-center cursor-pointer">
                    <img src={backIcon} alt="back" width={14} height={14} />
                    <span className="ml-2 text-link">Back</span>
                </Link>
                <h2 className="ml-5">Village Layout</h2>
            </div>
            <div className="flex relative flex-1">
                <div className="flex flex-col gap-4">
                    <VillageTagSelector
                        serialNumber="1"
                        isDisable={generateLoading || isLoading}
                        generateHandler={generateRoadHandler}
                        isLoading={isLoading}
                    />
                    <GenerateBtn
                        text="Create 3D preview"
                        isLoading={generateLoading}
                        onClick={generate3DHandler}
                        disabled={generateLoading || !mapjson.current}
                    />
                </div>
                <div className="flex-1 ml-5 flex flex-col items-center" ref={imageRef}>
                    <div className={`flex flex-col p-2 bg-white bg-opacity-10 rounded-md w-full min-h-[460px] ${isFullscreen ? 'flex-1' : ''}`}>
                        <div className="cursor-pointer text-white flex gap-2 justify-end w-full">
                            <img src={isFullscreen ? fullScreenExitIcon : fullScreenIcon} alt="fullscreen" onClick={toggleFullscreen} />
                        </div>
                        <div className='flex-1 flex justify-center items-center'>
                            {imageUrl ?
                                <img src={`data:image/png;base64,${imageUrl}`} alt="tag-to-layout" />
                                : <span className="text-white/20">No image</span>}
                        </div>
                    </div>
                    {usdzResult?.[2] && <div className='w-full flex justify-center mt-4'>
                        <a
                            href={usdzResult?.[2]}
                            target='_blank'
                            className="w-full flex justify-center border border-[#323232] rounded-md px-4 py-2 hover:text-link"
                        >
                            Open 3D City Viewer
                        </a>
                    </div>}
                </div>
                {/* <div className={`absolute inset-0 z-50 w-full h-full flex-col bg-black flex ${show3D ? 'flex' : 'hidden'}`}>
                    <div className='header'>
                        <div className='text-white py-2 px-4 cursor-pointer' onClick={() => setShow3D(false)}>Close</div>
                    </div>
                    <div className='content flex-1 flex justify-center items-center'>
                        <a
                            href={usdzResult?.[1]}
                            target='_blank'
                            className="border border-[#323232] rounded-md px-4 py-2 hover:text-link text-center cursor-pointer"
                        >
                            Open 3D City Viewer
                        </a>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default LabTagToVillageLayout;