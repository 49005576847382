import { useState, useEffect } from "react";
import "./index.css";
import cx from "classnames";
import { MapgenStyleItem } from "@/types";
import questionLineIcon from "@/assets/mapgen/questionLine.svg";

const terrainImgs: MapgenStyleItem[] = [
  { src: "/image/Forest-Wetland.png", name: "Forest Wetland", key: 0 },
  { src: "/image/Snow-Mountain.png", name: "Snow Mountain", key: 1 },
  { src: "/image/Gobi-Desert.png", name: "Gobi Desert", key: 2 },
];

function PreparedScene({
  serialNumber,
  clickImgHandler,
  onTerrainChange,
  terrain,
  actionRef,
  tourHandler,
}: {
  serialNumber: number;
  clickImgHandler: (terrain: number) => void;
  onTerrainChange: (terrain: number) => void;
  terrain: number;
  actionRef?: React.RefObject<HTMLButtonElement>;
  tourHandler?: () => void;
}) {
  const [selectedTerrain, setSelectedTerrain] = useState<number>(terrain);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedTerrain(terrain);
  }, [terrain]);

  const clickTerrain = (key: number) => {
    onTerrainChange(key);
    setSelectedTerrain(key);
  };

  const styleCardRender = (
    RenderList: MapgenStyleItem[],
    selected: number,
    selectHandler: (key: number) => void
  ) => {
    return RenderList.map((img) => (
      <div
        className={cx(
          "cursor-pointer items-center  card-item mr-4 mt-4 flex flex-col border-2 border-transparent",
          {
            "!border-white": img.key === selected,
          }
        )}
        onClick={() => selectHandler?.(img.key)}
        key={img.name}
      >
        <img src={img.src} alt={img.name} width={120} key={img.key} />
        <div className="tcenter mt-4">{img.name}</div>
        {img.key !== 0 && <span className="text-xs text-red-500 text-left">Experimental</span>}
      </div>
    ));
  };

  return (
    <div className="generate-style  items-center rounded-md p-6">
      <div className="flex justify-between items-center text-lg relative">
        <div className="flex">
          <span className="serial-number mr-2">{serialNumber}</span>
          <span className="mr-2">Choose style</span>
        </div>
        <img
          className="cursor-pointer"
          onClick={() => tourHandler?.()}
          src={questionLineIcon}
        />
      </div>
      <div className="items-center justify-between mt-5">
        <div className="pt-2">
          <div>Biome</div>
          <div className="card-wrap flex ">
            {styleCardRender(terrainImgs, selectedTerrain, clickTerrain)}
          </div>
        </div>

        <div className="relative " style={{ height: 60 }}>
          <button
            ref={actionRef}
            className={`button-3d-preview rounded-md text-base mt-6 absolute bottom-0 right-0 ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={async () => {
              setIsLoading(true);
              await clickImgHandler(selectedTerrain);
              setIsLoading(false);
            }}
          >
            {isLoading ? "Generating" : "Generate 3D preview"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PreparedScene;
