export const INITIAL_POSITION = { x: -500, y: -500 };
export const MAP_BOUNDARY_LIMIT = 1000;
export const ZOOM_MIN = 0.208;
export const ZOOM_MAX = 2;
export const ZOOM_STEP = 0.1;
export const ZOOM_DEFAULT = 0.208;
export const ZOOM_SLIDER_MIN = 0.5;
export const ZOOM_SLIDER_MAX = 5;
export const ZOOM_SLIDER_DEFAULT = 5;
export const ZOOM_FACTOR = 100;
export const MAP_CENTER = 500;
