import { TextToImageProps } from "@/types";
import "./TextToMap.css";
import { ChangeEvent, useState } from "react";
// import { v4 as uuidv4 } from 'uuid';
import refreshIcon from "@/assets/refresh.png";
import { sanitizeInput } from "@/lib/utils";
import { message } from "antd";

function getRandomPair(words: string[]): string[] {
    if (!words || words.length === 0) return [];

    // 获取两个不同的随机索引
    const firstIndex = Math.floor(Math.random() * words.length);
    let secondIndex = Math.floor(Math.random() * words.length);

    // 确保第二个索引不同于第一个索引
    while (secondIndex === firstIndex && words.length > 1) {
        secondIndex = Math.floor(Math.random() * words.length);
    }

    // 返回包含两个词的数组
    return [words[firstIndex], words[secondIndex]];
}


function TextToMap({
    serialNumber,
    clickCallback,
    title,
    isShowRandom = true,
    defaultText = "",
    exampleWords = []
}: TextToImageProps) {
    const [text, setText] = useState(defaultText);
    const [loading, setLoading] = useState<boolean>(false);
    const [exampleText, setExampleText] = useState<string[]>(getRandomPair(exampleWords));


    const clickTextHandler = (text: string) => {
        if (loading) return
        setText(sanitizeInput(text));
    }

    const changeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    }

    const generateImages = async (text: string) => {
        if (loading) return
        if (text.length === 0) {
            message.warning("Please enter a text first.");
            return
        }
        setLoading(true);
        try {
            clickCallback && await clickCallback(text);
        } catch (error: any) {
            console.log(error.toString());
            // 检查错误状态码是否为531
            if (error?.response?.status === 531) {
                message.warning(`Failed to generate map. Your input may contain unsupported terms. 
                    Currently supported terms include: mountains, rivers, plains, forests, lakes, deserts, islands, etc.`);
            } else {
                message.error("Failed to generate map.");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="text-to-image-bg p-4 rounded-md">
            <div className="flex text-lg mb-3">
                <span className="serial-number mr-2">{serialNumber}</span>
                <h1>{title}</h1>
            </div>
            <div>
                <div className="flex flex-col map-area-cotainer">
                    <textarea value={text} disabled={loading} onChange={changeHandler} maxLength={1000}></textarea>
                    {isShowRandom && <span className=" text-white opacity-30 my-2">Some examples for quick start: </span>}
                    <div className="flex justify-between text-sm">
                        <div className="flex-1 text-white/50">
                            {
                                exampleText.length > 0 && isShowRandom && exampleText.map((item, index) => {
                                    return <div
                                        className={`mr-2 ${loading ? "cursor-not-allowed" : ""} cursor-pointer`}
                                        onClick={() => clickTextHandler(item)}
                                        key={item}
                                    >
                                        {index + 1}. {item}
                                    </div>
                                })
                            }
                            <div className="generate">
                                <button
                                    onClick={() => generateImages(text)}
                                    disabled={loading || text.length <= 0}
                                    className={`generate-button mt-2 ${loading || text.length <= 0 ? "disabled" : ""}`}
                                >
                                    {loading ?
                                        <p className="flex items-center">
                                            <span className="mr-2">Generating...</span>
                                            <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                            </svg>
                                        </p>
                                        : "Generate Map"}
                                </button>
                            </div>
                        </div>
                        {isShowRandom && <div onClick={() => {
                            setExampleText(getRandomPair(exampleWords));
                        }}>
                            <img 
                                src={refreshIcon} 
                                alt="refresh" 
                                width={18} 
                                height={18} 
                                className="refresh-icon"
                            />
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TextToMap;


