// @ts-nocheck
import _ from "lodash";

import DragOperations from "./drag.ts";
import ZoomOperations from "./zoom.ts";
import MapGen from "@/index.ts";

class MapOperations {
    private zoomOpe: ZoomOperations;
    private dragOpe: DragOperations;
    constructor(mapElement: HTMLElement, config: Record<string, any>, mapGen: MapGen) {
        //mapGen.setEditMode(false);

        const { supports } = config;
        supports.forEach((subConfig: Record<string, any>) => {
            if (subConfig.type === "zoom") {
                this.zoomOpe = new ZoomOperations(mapGen, mapElement, subConfig);
            }
            if (subConfig.type === "pan") {
                this.dragOpe = new DragOperations(mapGen, mapElement, subConfig);
            }
        });
    }

    public getZoom() {
        return this.zoomOpe;
    }

    public getDrag() {
        return this.dragOpe;
    }

    public dispose() {
        this.zoomOpe.dispose();
        this.dragOpe.dispose();
    }
}

export default MapOperations;
