import MapGen from "../index.ts";

abstract class BasePlugin {
    protected mapgen: MapGen | null = null;
    
    /**
     * 插件初始化方法
     */
    public abstract onInit(mapgen: MapGen): Promise<void>;
    
    /**
     * 插件销毁方法
     */
    public abstract dispose(): void;

    public abstract getKey(): string;

    public abstract getSliders(): Record<string, HTMLElement>;

    public abstract getValue(key: string): number | null;

    public abstract setValue(key: string, value: number): void;

    public abstract setAllValue(value: Record<string, number>): void;

    public abstract getAllValue(): Record<string, number>;
    
    /**
     * 获取地图生成器实例
     */
    protected getMapgen(): MapGen {
        if (!this.mapgen) {
            throw new Error('Mapgen instance not initialized');
        }
        return this.mapgen;
    }
}

export default BasePlugin;