import villageIcon from "@/assets/village1.svg";
import { formatDateString, getLocalToken } from "@/lib/utils";
import { ScenesRenderTypeEnums, TaskCardProps } from "@/types";

import "./taskCard.css";

import ImageComponent from "../FallbackSrc/FallbackSrc";
import ueIcon from "@/assets/ue-icon.svg";
import unityIcon from "@/assets/unity-icon.svg";
import userIcon from "@/assets/user-icon.svg";

const taskProgress: { [key: number]: string } = {
    100: "Generating Model",
    200: "Generating Model",
    210: "Model completed",
    220: "Waiting",
    230: "Generating .geo files",
    241: "Failed",
    242: "Failed",
    260: "Waiting",
    300: "Generating scene",
    360: "Scene executing",
    371: ".geo file download failed",
    900: "Ready",
};

const fontColor = (status: number) => {
    if (status === 241 || status === 242) return { color: "red" };
    if (status === 900) return { color: "#53D2EE" };
    return {};
};

function TaskCard({
    imgSrc,
    status,
    taskID,
    viewClick,
    deleteClick,
    coord,
    pageType,
    renderType,
}: TaskCardProps) {
    return (
        <div className="container flex w-[16.5rem] flex-col gap-y-3 rounded-lg p-3 text-xs">
            <div className="relative">
                <ImageComponent
                    imgSrc={imgSrc}
                    fallbackSrc={
                        import.meta.env.VITE_APP_BASE_URL +
                        import.meta.env.VITE_APP_ASSET_FILE +
                        taskID +
                        `/map.png?token=${getLocalToken()}`
                    }
                    alt={taskID}
                    className="h-48 w-full object-cover"
                />
                {coord?.length > 0 &&
                    coord.map((pos) => {
                        return (
                            <img
                                key={pos.vid}
                                src={villageIcon}
                                className="absolute h-40 object-cover"
                                style={{
                                    top: `${(pos.y * 100).toFixed(4)}%`,
                                    left: `${(pos.x * 100).toFixed(4)}%`,
                                }}
                                width={24}
                                height={24}
                            />
                        );
                    })}
            </div>
            <p className="flex items-center gap-x-1.5">
                <img src={userIcon} alt="userIcon" className="h-[18px] w-[18px]" />
                {localStorage.getItem("username")}
            </p>
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-1.5">
                    {Number(renderType) === ScenesRenderTypeEnums.Unity ? (
                        <img
                            src={unityIcon}
                            alt="unityIcon"
                            className={`h-[18px] w-[18px] ${status === 900 ? "" : "hidden"}`}
                        />
                    ) : (
                        <img
                            src={ueIcon}
                            alt="ueIcon"
                            className={`h-[18px] w-[18px] ${status === 900 ? "" : "hidden"}`}
                        />
                    )}
                    <span style={fontColor(status)}>{taskProgress[status]}</span>
                </div>
                <span className="date">{`Last updated: ${formatDateString(taskID)}`}</span>
            </div>
            <div className="flex space-x-2">
                <button
                    onClick={() => viewClick(taskID, coord)}
                    className="view-btn flex-1 rounded-md py-1"
                    id={taskID}
                >
                    {pageType === "home" ? "Continue" : "View"}
                </button>
                {pageType === "home" ? (
                    ""
                ) : (
                    <button
                        onClick={() => deleteClick(taskID)}
                        className="view-btn flex-1 rounded-md py-1"
                    >
                        Delete
                    </button>
                )}
            </div>
        </div>
    );
}
export default TaskCard;
