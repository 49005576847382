import { Tooltip } from "antd";

interface OptionType {
    value: string;
    tooltip: string;
}

interface RadioGroupProps {
    label: string;
    options: OptionType[];
    selected: string;
    disabled?: boolean;
    onChange: (value: string) => void;
}

function RadioGroup({
    label,
    options,
    selected,
    disabled = false,
    onChange
}: RadioGroupProps) {
    return (
        <div className="mb-5">
            <p className="text-white opacity-50">{label}</p>
            {options.map((option) => (
                <Tooltip key={option.value} title={option.tooltip}>
                    <div
                        className={`
                            border rounded-md px-3 py-1 mr-2 mb-2 inline-flex items-center
                            ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
                            ${selected === option.value
                                ? 'border-white bg-gradient-to-r from-[#345777] to-[#763BAF] text-white'
                                : 'border-gray-500 text-gray-400'
                            }
                        `}
                        onClick={() => !disabled && onChange(option.value)}
                    >
                        <div
                            className={`
                                w-3 h-3 rounded-full border-2 mr-2
                                ${selected === option.value
                                    ? 'border-white border-4'
                                    : 'border-gray-400 bg-transparent'
                                }
                            `}
                        />
                        <span>{option.value}</span>
                    </div>
                </Tooltip>
            ))}
        </div>
    );
}

export default RadioGroup;