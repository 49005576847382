// hooks/useMapgenTour.ts
import { useRef, useState } from "react";

export const useMapgenTour = () => {
    // Tour 开关状态
    const [openTour, setOpenTour] = useState<boolean>(false);
    // 当前 Tour 步骤
    const [currentTour, setCurrentTour] = useState<number>(0);

    const step1Ref = useRef<HTMLDivElement>(null);
    const step2Ref = useRef<HTMLDivElement>(null);
    const step3Ref = useRef<HTMLDivElement>(null);
    const step4Ref = useRef<HTMLDivElement>(null);
    const step5Ref = useRef<HTMLButtonElement>(null);

    const tourHandler = (step: number) => {
        setOpenTour(true);
        setCurrentTour(step);
    };

    return {
        openTour,
        setOpenTour,
        currentTour,
        setCurrentTour,
        step1Ref,
        step2Ref,
        step3Ref,
        step4Ref,
        step5Ref,
        tourHandler,
    };
};
