import Sketch from '@/components/Sketch';
import checkmark from "@/assets/checkmark.png";
import { SketchRef } from '@/components/Sketch/sketch';

interface StepOneProps {
    isLoading: boolean;
    handleStepOne: () => void;
    sketchRef: React.MutableRefObject<SketchRef | null>;
}

function StepOne({ isLoading, handleStepOne, sketchRef }: StepOneProps) {
    return (
        <div className="flex">
            {/* <div className='w-[200px] px-2'>
                <p className="opacity-30">Draw the boundary of the town</p>
                <p className="text-red-500">
                    Please ensure the shape is drawn as a closed figure.
                    If it is drawn as an open figure, such as a ring,
                    it may cause errors during generation.
                </p>
            </div> */}
            <div>
                <Sketch ref={sketchRef} />
                <button
                    onClick={handleStepOne}
                    disabled={isLoading}
                    className="flex items-center border border-[#323232] rounded-md px-4 py-2 mt-4 m-auto"
                >
                    {!isLoading && <img src={checkmark} alt="checkmark" width={14} height={14} />}
                    <span className="ml-2">{isLoading ? 'Generating...' : 'Next step'}</span>
                </button>
            </div>
        </div>
    );
}

export default StepOne;
