import ChevronLeft from '@/assets/chevron_left.svg';
import ChevronRight from '@/assets/chevron_right.svg';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
  }
  
  const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    onPageChange,
  }) => {
    const renderPageNumbers = () => {
      const pages: (number | string)[] = [];
      
      // 添加第一页
      pages.push(1);
      
      if (currentPage > 3) {
        pages.push('...');
      }
      
      // 添加当前页附近的页码
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      
      if (currentPage < totalPages - 2) {
        pages.push('...');
      }
      
      // 添加最后一页
      if (totalPages > 1) {
        pages.push(totalPages);
      }
      
      return pages.map((page, index) => (
        <button
          key={index}
          onClick={() => typeof page === 'number' && onPageChange(page)}
          className={`
            w-8 h-8 mx-1 rounded
            ${typeof page === 'number' 
              ? currentPage === page
                ? 'bg-white/10 text-white'
                : 'hover:bg-white/10'
              : 'text-white'
            }
          `}
        >
          {page}
        </button>
      ));
    };
  
    return (
      <div className="flex items-center justify-center space-x-2">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="w-6 h-6 rounded"
        >
          <img src={ChevronLeft} alt="previous" className="w-full h-full" />
        </button>
        
        {renderPageNumbers()}
        
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="w-6 h-6 rounded"
        >
          <img src={ChevronRight} alt="next" className="w-full h-full" />
        </button>
      </div>
    );
  };
  
  export default Pagination;