import { useState } from "react";
import { message } from "antd";

import Warning from "../Warning/Warning";
import downLine from "@/assets/down-line.svg";
import downloadIcon from "@/assets/download.svg";
import fullScreenExitIcon from "@/assets/fullscreen_exit.svg";
import fullScreenIcon from "@/assets/fullscreen.svg";
import ueIcon from "@/assets/ue-icon.svg";
import unityIcon from "@/assets/unity-icon.svg";
import { getLocalToken } from "@/lib/utils";
import { useHideUnity } from "@/pages/Labs/hooks/useHideUnity";
import { ScenesRenderTypeEnums } from "@/types";

interface ModelPreviewHeaderProps {
    isModelFullscreen: boolean;
    genPersceneLoading: boolean;
    modelUrl: string;
    taskid: string;
    MODEL: string;
    enterModelFullscreen: () => void;
    exitModelFullscreen: () => void;
    generate: (taskId: string, render_type?: number) => void;
    renderType?: number;
    source?: string;
}

const ModelPreviewHeader = ({
    isModelFullscreen,
    genPersceneLoading,
    modelUrl,
    taskid,
    MODEL,
    enterModelFullscreen,
    exitModelFullscreen,
    generate,
    renderType,
    source,
}: ModelPreviewHeaderProps) => {
    const [showWarning, setShowWarning] = useState(false);
    const hideUnityLabs = useHideUnity();
    const labsGenerate3DSceneRender = () => {
        return (
            <div className="group relative">
                <button
                    className="gradient-border flex h-8 min-w-[180px] items-center justify-between rounded-md text-base"
                    style={{
                        background: !genPersceneLoading
                            ? "linear-gradient(to right, #5FB3CE, #132976)"
                            : "linear-gradient(to right, rgba(95, 179, 206, 0.5), rgba(19, 41, 118, 0.5))",
                    }}
                >
                    {genPersceneLoading ? "Generating" : "Generate 3D scene"}
                    <img src={downLine} alt="" className="ml-2 h-4" />
                </button>
                <div className="visibility-hidden absolute inset-x-0 top-[33px] z-10 cursor-pointer rounded-md border border-[#323232] bg-[#101010]/[.9] opacity-0 transition-opacity duration-300 ease-in-out group-hover:visible group-hover:opacity-100">
                    {(renderType === ScenesRenderTypeEnums.UE || !renderType) && (
                        <button
                            className={`flex h-10 w-full items-center gap-x-1.5 rounded-t-md pl-3 hover:bg-[#101010] ${renderType === ScenesRenderTypeEnums.UE ? "bg-[#101010]" : ""}`}
                            onClick={() => {
                                if (!renderType) {
                                    generate(taskid, ScenesRenderTypeEnums.UE);
                                } else if (renderType !== ScenesRenderTypeEnums.UE) {
                                    setShowWarning(true);
                                }
                            }}
                        >
                            <img src={ueIcon} alt="ueIcon" className="h-6 w-6" />
                            <span>in Unreal Engine</span>
                        </button>
                    )}
                    {(renderType === ScenesRenderTypeEnums.Unity || !renderType) && (
                        <button
                            className={`flex h-10 w-full items-center gap-x-1.5 rounded-b-md pl-3 hover:bg-[#101010] ${renderType === ScenesRenderTypeEnums.Unity ? "bg-[#101010]" : ""}`}
                            onClick={() => {
                                if (!renderType) {
                                    generate(taskid, ScenesRenderTypeEnums.Unity);
                                } else if (renderType !== ScenesRenderTypeEnums.Unity) {
                                    setShowWarning(true);
                                }
                            }}
                        >
                            <img src={unityIcon} alt="unityIcon" className="h-6 w-6" />
                            <span>in Unity</span>
                        </button>
                    )}
                </div>
            </div>
        );
    };
    const defaultGenerate3DSceneRender = () => {
        return (
            <button
                className="gradient-border rounded-md text-base"
                style={{
                    background: !genPersceneLoading
                        ? "linear-gradient(to right, #5FB3CE, #132976)"
                        : "linear-gradient(to right, rgba(95, 179, 206, 0.5), rgba(19, 41, 118, 0.5))",
                }}
                onClick={() => generate(taskid)}
            >
                {!genPersceneLoading ? "Generate 3D scene" : "Generating"}
            </button>
        );
    };
    return (
        <>
            <div className="mt-2 flex justify-between">
                <p className="flex items-center py-2 text-base font-light text-white">
                    <span>3D Preview</span>
                </p>
                <div className="flex items-center">
                    <button
                        onClick={() =>
                            isModelFullscreen ? exitModelFullscreen() : enterModelFullscreen()
                        }
                        className="outline-none"
                    >
                        <img
                            src={isModelFullscreen ? fullScreenExitIcon : fullScreenIcon}
                            alt=""
                            className="h-5"
                        />
                    </button>
                    <button
                        className="mx-4"
                        onClick={() => {
                            if (!modelUrl) {
                                message.warning("No files to download.");
                                return;
                            }
                            const url = MODEL + taskid + `?token=${getLocalToken()}`;
                            window.open(url, "_blank");
                        }}
                    >
                        <img src={downloadIcon} alt="" />
                    </button>
                    {(source === "labs" || renderType) && !hideUnityLabs
                        ? labsGenerate3DSceneRender()
                        : defaultGenerate3DSceneRender()}
                </div>
            </div>
            <Warning
                isVisible={showWarning}
                text={
                    <>
                        The current project already includes a 3D scene. Generating the scene in a
                        new environment/engine will{" "}
                        <span className="text-[#FFA235]">create a duplicate</span>, allowing you to
                        view the version in the different engine.
                    </>
                }
                mainClassName="mb-9"
                waringContainerClassName="&[span]: text-white"
                hideWarningIcon
                continueText="Continue, create a duplicate"
                onCancel={() => {
                    setShowWarning(false);
                }}
                onContinue={() => {
                    setShowWarning(false);
                    if (renderType === ScenesRenderTypeEnums.Unity) {
                        generate(taskid, ScenesRenderTypeEnums.UE);
                    } else {
                        generate(taskid, ScenesRenderTypeEnums.Unity);
                    }
                }}
            />
        </>
    );
};

export default ModelPreviewHeader;
