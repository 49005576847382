import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from "react";

import WeatherControl from "../WeatherControl/WeatherControl";
import UEPlayer from "@/lib/ueplayer";
import { getAssetServer, getVideoServer, getWebSocketServer } from "@/lib/utils";
import { UeplayerRefProps, UESceneProps } from "@/types";

const UEScene = (
    { taskId, callback, streamID, renderType }: UESceneProps,
    ref: Ref<UeplayerRefProps>,
) => {
    const [loadingText, setLoadingText] = useState<string>("");
    const videoRef = useRef<HTMLVideoElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const ueplayerRef = useRef<UEPlayer | null>(null);
    const moveRef = useRef<HTMLDivElement>(null);
    const weatherRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
        getUeplayerRef: () => {
            return ueplayerRef.current;
        },
    }));

    useEffect(() => {
        console.log("Welcome To 3D Video");
        console.log("renderType: ", renderType);
        console.log("streamID: ", streamID);
        console.log("taskId: ", taskId);
        let mapUrl: string;
        const task_id = BigInt(taskId);
        const release_cutoff_time = BigInt("2024092700000000000");
        if (task_id >= release_cutoff_time) {
            mapUrl = getAssetServer() + taskId + "/mini_map.png";
        } else {
            mapUrl = getAssetServer() + taskId + "/map.png";
        }

        if (streamID) {
            setLoadingText("3D scene is loading, Please wait patiently...");

            ueplayerRef.current = new UEPlayer({
                ctrlUrl: getWebSocketServer() + taskId,
                videoUrl: getVideoServer(renderType),
                streamID: streamID,
                video: videoRef.current,
                map: mapRef.current,
                mapUrl: mapUrl,
                move: moveRef.current,
                weather: weatherRef.current,
                renderType: renderType,
                onLoss: (status: number) => {
                    console.log("Stream LOST");
                    console.log({ status });
                    setLoadingText("");
                    callback(status);
                },
                onConnected: (content: string ="") => {
                    setLoadingText(content);
                },
            });
        }

        return () => {
            console.log("UE Bye");
            setLoadingText("");
            ueplayerRef.current?.destroy();
            ueplayerRef.current && (ueplayerRef.current = null);
        };
    }, [streamID]);

    return (
        <>
            <div className={`image-stream-container flex flex-1 items-center justify-center`}>
                <div className="action-container relative h-full w-full">
                    {loadingText && (
                        <div className="absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 transform rounded-md bg-[#191919] px-4 py-2 text-center text-sm font-light tracking-wide text-white/50">
                            {loadingText}
                        </div>
                    )}
                    <video
                        muted
                        autoPlay
                        ref={videoRef}
                        id="video"
                        className="absolute left-0 top-0 h-full w-full object-cover"
                    ></video>
                    <div ref={moveRef}>
                        <div className="move">
                            <button className="keyboard-bg absolute bottom-[4rem] left-[4.5rem] rounded-md px-4 py-3">
                                W
                            </button>
                            <button className="keyboard-bg absolute bottom-4 left-4 rounded-md px-5 py-3">
                                A
                            </button>
                            <button className="keyboard-bg absolute bottom-4 left-[4.5rem] rounded-md px-5 py-3">
                                S
                            </button>
                            <button className="keyboard-bg absolute bottom-4 left-32 rounded-md px-5 py-3">
                                D
                            </button>
                            <button className="keyboard-bg absolute bottom-4 left-[11.7rem] rounded-md px-5 py-3">
                                V
                            </button>
                            <button className="keyboard-bg absolute bottom-4 left-[15.5rem] rounded-md px-10 py-3">
                                Space
                            </button>
                        </div>
                        <div className="angle">
                            <button className="keyboard-bg absolute bottom-[4rem] left-[28rem] rounded-md px-5 py-3">
                                &uarr;
                            </button>
                            <button className="keyboard-bg absolute bottom-4 left-96 rounded-md px-5 py-3">
                                &larr;
                            </button>
                            <button className="keyboard-bg absolute bottom-4 left-[28rem] rounded-md px-5 py-3">
                                &darr;
                            </button>
                            <button className="keyboard-bg absolute bottom-4 left-[31.5rem] rounded-md px-5 py-3">
                                &rarr;
                            </button>
                        </div>
                    </div>

                    <WeatherControl ref={weatherRef} />

                    <div className="mini-map absolute bottom-4 right-4" ref={mapRef}></div>
                </div>
            </div>
        </>
    );
};

export default forwardRef(UEScene);
