export const settlements = [
    "Riverside settlement with terraced farmland along the banks",
    "Mountain village nestled between two steep peaks",
    "Coastal fishing village with a protected natural harbor",
    "Forest clearing with scattered homesteads",
    "Hilltop village overlooking a vast valley",
    "Lakeside settlement with connecting wooden bridges",
    "Desert oasis village around a natural spring",
    "Valley village protected by surrounding cliffs",
    "Plains settlement with windmills and grain fields",
    "Village in a volcanic region with hot springs",
    "Tundra settlement with natural ice barriers",
    "Village in rolling hills with grazing meadows",
    "Settlement along ancient trade routes",
    "Forest village connected by elevated walkways",
    "Village in a protected bay with small docks",
    "Highland settlement with stone buildings",
    "Valley village split by a meandering stream",
    "Village near thermal vents with steam features",
    "Settlement in bamboo groves with water gardens",
    "Village on interconnected small islands",
    "Settlement in a natural amphitheater formation",
    "Village protected by natural rock formations",
    "Riverside trading post at a fork junction",
    "Village in a fertile basin with irrigation channels",
    "Settlement along ancient pilgrimage routes",
    "Village surrounded by sacred groves",
    "Highland settlement with terrace farming",
    "Village near natural mineral springs",
    "Settlement integrated with limestone caves",
    "Village in a protected forest glen with waterfalls"
]