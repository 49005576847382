import React, { useRef, useState, useEffect } from "react";
import { SketchRef } from '@/components/Sketch/sketch';

const Sketch = React.forwardRef<SketchRef>((_, ref) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [drawing, setDrawing] = useState(false);
  const [lineWidth, setLineWidth] = useState(80);
  const [history, setHistory] = useState<string[]>([]);
  const [canvasWidth, canvasHeight] = [600, 600]; // 画布显示尺寸

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // 提高分辨率
    const dpr = window.devicePixelRatio || 1;
    // console.log("window.devicePixelRatio:", window.devicePixelRatio);
    console.log(dpr);
    
    canvas.width = canvasWidth ;
    canvas.height = canvasHeight ;

    const ctx = canvas.getContext("2d");
    if (ctx) {
      // ctx.scale(dpr, dpr); // 缩放上下文以匹配设备像素
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    }
  }, [canvasWidth, canvasHeight]);

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx) return;
    ctx.beginPath();
    ctx.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    setDrawing(true);
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!drawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d", { willReadFrequently: true });
    if (!canvas || !ctx) return;
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.strokeStyle = "#000000";
    ctx.lineWidth = lineWidth;
    ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    ctx.stroke();
  };

  const stopDrawing = () => {
    if (!drawing) return;
    setDrawing(false);
    saveToHistory();
  };

  const saveToHistory = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) return;

    // 只保存到历史记录，移除自动触发 onDrawingComplete
    const dataUrl = canvas.toDataURL();
    setHistory((prevHistory) => [...prevHistory, dataUrl]);
  };

  const getCurrentImageData = (): ImageData | undefined => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) return;

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    return imageData;
  };

  const undo = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx || history.length === 0) return;
    const newHistory = [...history];
    newHistory.pop();
    setHistory(newHistory);

    const imageData = newHistory[newHistory.length - 1];
    const img = new Image();
    img.src = imageData || "";
    img.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    setHistory([]);
  };

  // 使用 useImperativeHandle 暴露方法
  React.useImperativeHandle(ref, () => ({
    getCurrentImageData
  }));

  return (
    <div className="flex flex-col">
      <div className="p-2 bg-gray-100 w-full">
        <div className="flex items-center justify-between space-x-6">
          <div className="flex items-center">
            <label className="mr-2 font-medium text-gray-700">Brush Color</label>
            <div className="w-6 h-6 bg-black rounded-sm"></div>
          </div>

          <div className="flex items-center flex-1 max-w-xs">
            <label className="mr-2 font-medium text-gray-700">Brush Size</label>
            <div className="flex-1">
              <input
                type="range"
                min="1"
                max="100"
                value={lineWidth}
                onChange={(e) => setLineWidth(Number(e.target.value))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                style={{
                  background: 'linear-gradient(to right, #3B82F6 0%, #3B82F6 ' + (lineWidth) + '%, #E5E7EB ' + (lineWidth) + '%, #E5E7EB 100%)',
                }}
              />
              <span className="mt-1 text-center text-gray-700">{lineWidth}px</span>
            </div>
          </div>

          <div className="flex gap-2">
            <button 
              onClick={undo} 
              disabled={history.length === 0}
              className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50 hover:bg-blue-600"
            >
              Undo
            </button>
            <button 
              onClick={clearCanvas}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <canvas
        width={canvasWidth}
        height={canvasHeight}
        ref={canvasRef}
        style={{
          display: "block"
        }}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseLeave={stopDrawing}
      />
    </div>
  );
});

export default Sketch;
