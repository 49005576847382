export const PHASES = {
    ELEVATION: "elevation",
    BIOMES: "biomes",
    RIVERS: "rivers",
    RENDER: "render",
    OTHERS: "others",
} as const;

export const VISIBLE_PHASES = [PHASES.ELEVATION, PHASES.RIVERS, PHASES.OTHERS] as const;

export const VISIBLE_PHASES_RENDER = [PHASES.RENDER] as const;

export type PhaseType = (typeof PHASES)[keyof typeof PHASES];
