import { useNavigate } from "react-router-dom";

interface LabsCardProps {
  title: string;
  description: string;
  imageUrl: string;
  routePath: string;
  routeConfig?: {
    [x in string]: string;
  };
}

function LabsCard({ title, description, imageUrl, routePath, routeConfig = {} }: LabsCardProps) {
  const navigate = useNavigate();
  return (
    <div className="labs-card px-6 rounded-xl bg-white bg-opacity-10 py-6 border border-transparent hover:border-white/10 transition-all">
      <div className="flex flex-col items-center">
        <img src={imageUrl} alt={title} className="h-[180px] w-[180px] rounded-lg" />
        <h3 className="mt-4 w-[200px] p-0 text-lg text-white/70">{title}</h3>
        <p className="mt-2 h-[40px] w-[200px] p-0 text-sm text-white/70">{description}</p>
        <button
          className="mt-8 w-full rounded-md border border-white/40 px-4 py-1 text-sm transition-all hover:bg-white hover:bg-opacity-10 text-white/70"
          onClick={() =>
            navigate(routePath, {
              state: routeConfig,
            })
          }
        >
          Try it now
        </button>
      </div>
    </div>
  );
}

export default LabsCard;
