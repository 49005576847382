// @ts-nocheck
import param from "../config/index.ts";
import { initialParams } from "../data/initParam.ts";
import BasePlugin from "./base.ts";

import "./index.css";

import { PHASES, VISIBLE_PHASES, VISIBLE_PHASES_RENDER } from "../config/const.ts";

interface SliderCategory {
    title: string;
    items: SliderItem[];
}

interface SliderParam {
    name: string;
    title: string;
    value: number;
    min: number;
    max: number;
    isshow: string;
}

const PARAMETER_ADJUSTMENTS: Record<string, ParameterAdjustment> = {
    lg_river_width: {
        name: "lg_river_width",
        offset: -5,
        validate: (value: number) => {
            return Math.min(0, Math.max(-5, value));
        },
    },
} as const;

class SliderPlugin extends BasePlugin {
    private container: HTMLElement | null = null;
    private sliders: Map<string, HTMLInputElement> = new Map();
    private visiblePhase: string[] = ["elevation", "rivers", "render", "biomes"];

    public async onInit(mapgen: MapGen): Promise<void> {
        try {
            this.mapgen = mapgen;
            this.initializeSliders();
        } catch (error) {
            console.error("Slider plugin initialization failed:", error);
            throw error;
        }
    }

    private initializeSliders() {
        const sliderContainer = document.getElementById("sliders");
        const sliderRenderContainer = document.getElementById("sliders-render");
        const visiblePhases = VISIBLE_PHASES;
        this.createSliders(visiblePhases, sliderContainer);
        this.createSliders(VISIBLE_PHASES_RENDER, sliderRenderContainer, 1);
    }

    private createSliders(
        visiblePhases: string[],
        sliderContainer: HTMLElement,
        maxChildNodes = 3,
    ) {
        for (let phase of Object.values(PHASES)) {
            // 创建phase容器
            const phaseContainer = this.createPhaseContainer(phase, visiblePhases.includes(phase));

            // const paramConfig = [name, title, value, min, max];
            //@ts-ignore
            initialParams[phase].forEach(([name, title, value, min, max, isshow]) => {
                const specialValue = ["lg_river_width"];
                // const adjustedValue = specialValue.includes(name) ? value - 5 : value;
                const adjustedValue = this.adjustParameterValue(name, value);
                param[phase][name] = adjustedValue;

                if (name === "seed") return;

                const sliderLabel = this.createSliderLabel(
                    name,
                    title,
                    phase,
                    value,
                    min,
                    max,
                    isshow,
                );
                //this.sliders.set(name, sliderLabel);

                // 只添加到可见的phase容器中
                if (sliderLabel && visiblePhases.includes(phase)) {
                    if (name === "mountain_height") {
                        const existingSlider = document.querySelector(
                            `.silder-container-elevation #slider-mountain_height`,
                        );
                        if (!existingSlider) {
                            document
                                .querySelector(`.silder-container-elevation`)
                                .appendChild(sliderLabel);
                        }
                    } else {
                        phaseContainer.appendChild(sliderLabel);
                    }
                }
            });
            const childNodes = sliderContainer?.getElementsByClassName("silder-container");
            // 将phase容器添加到主容器
            if (visiblePhases.includes(phase) && childNodes.length < maxChildNodes) {
                sliderContainer?.appendChild(phaseContainer);
            }
        }
    }

    // 创建phase容器
    private createPhaseContainer(phase: string, isVisible: boolean): HTMLElement {
        const container = document.createElement("div");
        if (isVisible) {
            container.className = ` silder-container silder-container-${phase} flex-item`;
            const header = document.createElement("h3");
            header.className = "!text-xs";
            let textContent = phase;

            const phaseTextMap = {
                elevation: "Mountains",
                render: "Viewpoint",
                rivers: "Rivers",
                others: "Others",
            };

            textContent = phaseTextMap[phase] || phase;
            header.textContent = textContent;
            container.appendChild(header);
        }
        return container;
    }

    private adjustParameterValue(name: string, value: number): number {
        const adjustment = PARAMETER_ADJUSTMENTS[name];
        if (!adjustment) return value;

        let adjustedValue = value + adjustment.offset;

        if (adjustment.validate) {
            adjustedValue = adjustment.validate(adjustedValue);
        }

        return adjustedValue;
    }

    // 创建滑块标签组件
    private createSliderLabel(
        name: string,
        title: string,
        phase: string,
        value: number,
        min: number,
        max: number,
        isshow: string,
    ): HTMLElement {
        if (isshow === "hidden") {
            return;
        }
        const label = document.createElement("label");
        label.id = `${name}`;
        label.className = "slider-label";

        const titleSpan = document.createElement("span");
        titleSpan.textContent = title;

        const slider = this.createSlider(phase, name, value, min, max);

        label.append(titleSpan, slider);
        return label;
    }

    private createValueInput(value: string): HTMLElement {
        const valueInput = document.createElement("input");
        valueInput.className = "slider-value"; // text input
        valueInput.type = "text";
        valueInput.value = value;
        valueInput.style.width = "50px"; // 设置合适的宽度
        return valueInput;
    }

    private createSlider(
        category: string,
        name: string,
        value: number,
        min: number,
        max: number,
    ): HTMLElement {
        const container = document.createElement("div");
        const label = document.createElement("label");
        const slider = document.createElement("input");

        const step = name === "seed" ? 1 : 0.001;
        // 设置 range input
        slider.type = "range";
        slider.min = min.toString();
        slider.max = max.toString();
        slider.step = step.toString();
        slider.value = value.toString();
        const valueInput = this.createValueInput(slider.value);

        const handleSliderChange = () => {
            let value = slider.valueAsNumber;
            const specialValue = ["lg_river_width"];
            // 映射特殊类别
            const mappedCategory = category === "others" ? "elevation" : category;
            valueInput.value = value.toString();

            // 处理特殊参数值
            const adjustedValue = this.adjustParameterValue(name, value);

            param[mappedCategory][name] = adjustedValue;

            // 更新 text input 的值
            if (this.mapgen) {
                if (category === "render") {
                    this.mapgen.renderer.updateView(param.render);
                } else {
                    if (name === "island") {
                        this.mapgen.generate({ isForceUpdate: true });
                    } else {
                        this.mapgen.generate();
                    }
                }
            }
        };

        // text input 的变化处理
        const handleTextChange = () => {
            let value = parseFloat(valueInput.value);

            // 确保值在有效范围内
            value = Math.max(parseFloat(slider.min), Math.min(parseFloat(slider.max), value));

            // 更新两个输入框的值
            slider.value = value.toString();
            valueInput.value = value.toString();
            this.setValue(name, value.toString());

            //更新渲染值
            const adjustedValue = this.adjustParameterValue(name, value);
            param[category][name] = adjustedValue;

            if (this.mapgen) {
                if (category === "render") {
                    this.mapgen.renderer.updateView(param.render);
                } else {
                    this.mapgen.generate();
                }
            }
        };

        // 添加事件监听器
        slider.addEventListener("input", handleSliderChange);
        valueInput.addEventListener("change", handleTextChange);
        valueInput.addEventListener("blur", handleTextChange);

        label.textContent = name;
        container.appendChild(slider);
        container.appendChild(valueInput);

        return container;
    }

    /**
     * 获取滑块值
     */
    public getValue(key: string): number | null {
        const slider = this.getSlider(key);
        return slider ? Number(slider.querySelector(".slider-value").value) : null;
    }

    public getAllValue(): Record<string, number> {
        const values: Record<string, number> = {};
        const sliders = this.getAllSlider();
        sliders.forEach((slider) => {
            if (!slider) return;
            const key = slider.getAttribute("id");
            if (!key) return;
            values[key] = Number(slider.querySelector(".slider-value")?.value);
        });
        return values;
    }

    public setAllValue(values: Record<string, number>): void {
        if (!values || Object.keys(values).length === 0) return;
        Object.keys(values).forEach((key) => {
            this.setValue(key, values[key]);
        });
    }

    public getSlider(key: string): HTMLElement | null {
        return document.getElementById(`${key}`);
    }

    public getAllSlider(): Map<string, HTMLElement> {
        return document.querySelectorAll(".slider-label");
    }

    /**
     * 设置滑块值
     */
    public setValue(key: string, value: number): void {
        const slider = this.getSlider(key);
        if (!slider || !key) return;

        const range = slider.querySelector('input[type="range"]');
        range.value = String(value);
        const input = slider.querySelector(".slider-value");
        input.value = String(value);

        // 触发change事件
        // slider.dispatchEvent(new Event('input'));

        // 找到对应的phase类别
        for (let phase of Object.values(PHASES)) {
            if (param[phase] && key in param[phase]) {
                // 处理特殊参数值调整
                const adjustedValue = this.adjustParameterValue(key, value);
                param[phase][key] = adjustedValue;

                // 更新视图
                if (this.mapgen) {
                    if (phase === "render") {
                        console.log(param.render);
                        this.mapgen.renderer.updateView(param.render);
                    } else {
                        this.mapgen.generate();
                    }
                }
                break;
            }
        }
    }

    /**
     * 销毁插件
     */
    public dispose(): void {
        // 移除所有事件监听
        // this.sliders.forEach((slider) => {
        //     slider.replaceWith(slider.cloneNode(true));
        // });

        // // 清空滑块集合
        // this.sliders.clear();

        // 移除容器
        this.container?.remove();
        this.container = null;

        // 清空引用
        this.mapgen = null;
    }
}

export default new SliderPlugin();
