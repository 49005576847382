import "./Warning.css";

import closeIcon from "@/assets/warning-close.png";
import warningIcon from "@/assets/warning.png";
import { WarningProps } from "@/types";

function Warning({
    isVisible,
    onCancel,
    onContinue,
    text,
    continueText,
    hideWarningIcon,
    mainClassName,
    waringContainerClassName,
}: WarningProps) {
    if (!isVisible) return null;

    const close = () => {
        onCancel && onCancel();
    };

    const ContinueHandler = () => {
        close();
        onContinue && onContinue();
    };

    return (
        <div className="warning-bg">
            <div className={`waring-container flex flex-col ${waringContainerClassName}`}>
                <header className="flex items-center justify-between">
                    <span className="text-lg">Warning</span>
                    <img
                        src={closeIcon}
                        alt="close"
                        width={24}
                        height={24}
                        className="cursor-pointer"
                        onClick={close}
                    />
                </header>
                <main className={`flex flex-1 items-center gap-x-4 ${mainClassName}`}>
                    <img
                        src={warningIcon}
                        hidden={hideWarningIcon}
                        alt="warning"
                        width={24}
                        height={24}
                    />
                    <span className="!max-w-full">{text}</span>
                </main>
                <footer className="warning-footer flex justify-end">
                    <button onClick={close}>Cancel</button>
                    {onContinue && (
                        <button className="continue ml-2" onClick={ContinueHandler}>
                            {continueText || "Continue"}
                        </button>
                    )}
                </footer>
            </div>
        </div>
    );
}
export default Warning;
