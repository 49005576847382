import { MouseEventHandler, ReactNode } from "react";

import { ScenesRenderTypeEnums } from "./page";

// Modal.tsx
export type ModalProps = {
    data: Array<string>;
    isShow: boolean;
    close: MouseEventHandler<HTMLButtonElement>;
    cancel: Function;
    confirm: Function;
};

// UEScene.tsx
export type UESceneProps = {
    taskId: string;
    renderType?: ScenesRenderTypeEnums;
    streamID: string;
    callback: (status: number) => void;
};

// History.tsx
export type Task = {
    uid: string;
    task_id: string;
    status: number;
    [key: string]: any;
};

// DrawingBoard.tsx
export type DrawingBoardProps = {
    imageListIndex: (n: number) => void;
    disableBrush: boolean;
    taskTempStatus: any;
    coord: Array<VillagePos>;
    switchImg: () => void;
    loadImage: () => void;
    hideUpload?: boolean;
};

export type DrawInfo = {
    radius: number;
    lWidth: number;
    strokeColor: string;
    step: number;
    canvasHistory: string[];
    eraserEnabled: boolean;
    lastPoint: { x: number; y: number };
    painting: boolean;
};

export type VillagePos = {
    vid: string;
    x: number;
    y: number;
};

export interface TaskCardProps {
    imgSrc: string;
    status: number;
    taskID: string;
    viewClick: (taskID: string, coord: Array<VillagePos>) => void;
    deleteClick: (taskID: string) => void;
    coord: Array<VillagePos>;
    pageType: string;
    renderType?: number;
}

// Download
export type DownloadProps = {
    isShow: boolean;
    taskid: string;
    closeWinodw: () => void;
    downLevel: () => void;
    downBase: () => void;
};

export type RandomImageProps = {
    isDisable: boolean;
    serialNumber: number;
    randomImg?: () => void;
    clickImg: (id: string) => void;
    imgList?: Array<{ id: string; src: string }>;
    clickRandomBtn: () => void;
    tourHandler?: () => void;
};

interface TerrainSizeOption {
    size: number;
    label: string;
    iconSize: string; // 用于控制图标大小的类名
}

export interface TerrainSizeProps {
    isDisable: boolean;
    serialNumber: number;
    callback: (size: number) => void;
    options?: TerrainSizeOption[];
    defaultSize?: number;
    tourHandler?: () => void;
    onOptionReset?: () => void;
}

export type TextToImageProps = {
    serialNumber: number;
    clickCallback: (text: string) => void;
    title: string;
    isShowRandom?: boolean;
    defaultText?: string;
    exampleWords?: string[];
};

export type TextImageProps = {
    serialNumber: number;
    clickImg: (text: string) => void;
    clearSelected?: number;
};

export type WarningProps = {
    isVisible: boolean;
    text: string | ReactNode;
    onCancel: () => void;
    onContinue?: () => void;
    continueText?: string;
    hideWarningIcon?: boolean;
    mainClassName?: string;
    waringContainerClassName?: string;
};

export type UploadProps = {
    isVisible: boolean;
    text?: ReactNode;
    onCancel: () => void;
    onContinue: (base64Image: string) => void;
    item: Array<string>;
};

export const MapStyleTerrain = {
    "Forest Wetland": 0,
    "Snow Mountain": 1,
    "Gobi Desert": 2,
};

export const mapStyleTown = {
    "Water Village": 0,
    "Medieval Village": 1,
    "Wooden House": 2,
    "Farm House": 3,
};
