export const BRUSH_SIZES_BY_ZOOM = {
    MEDIUM: { min: 0.208, max: 0.28, size: "medium" },
    SMALL: { min: 0.28, max: 0.416, size: "small" },
    TINY: { min: 0.416, max: 0.74, size: "tiny" },
    XTINY: { min: 0.74, max: Infinity, size: "xtiny" }, // 默认值
} as const;

export enum BRUSH_SIZES_KEY {
    xtiny = "1",
    tiny = "2",
    small = "3",
    medium = "4",
    large = "5",
}
enum BRUSH_SIZES_NAME {
    xtiny = "xtiny",
    tiny = "tiny",
    small = "small",
    medium = "medium",
    large = "large",
}
enum BRUSH_SIZES_RATE {
    xtiny = 4,
    tiny = 4,
    small = 4,
    medium = 4,
    large = 5,
}
enum BRUSH_SIZES_INNER_RADIUS {
    xtiny = 1,
    tiny = 2,
    small = 3,
    medium = 5,
    large = 10,
}
enum BRUSH_SIZES_OUTER_RADIUS {
    xtiny = 2,
    tiny = 4,
    small = 6,
    medium = 10,
    large = 16,
}
export const BRUSH_SIZES = {
    [BRUSH_SIZES_NAME.xtiny]: {
        key: BRUSH_SIZES_KEY.xtiny,
        rate: BRUSH_SIZES_RATE.xtiny,
        innerRadius: BRUSH_SIZES_INNER_RADIUS.xtiny,
        outerRadius: BRUSH_SIZES_OUTER_RADIUS.xtiny,
    },
    [BRUSH_SIZES_NAME.tiny]: {
        key: BRUSH_SIZES_KEY.tiny,
        rate: BRUSH_SIZES_RATE.tiny,
        innerRadius: BRUSH_SIZES_INNER_RADIUS.tiny,
        outerRadius: BRUSH_SIZES_OUTER_RADIUS.tiny,
    },
    [BRUSH_SIZES_NAME.small]: {
        key: BRUSH_SIZES_KEY.small,
        rate: BRUSH_SIZES_RATE.small,
        innerRadius: BRUSH_SIZES_INNER_RADIUS.small,
        outerRadius: BRUSH_SIZES_OUTER_RADIUS.small,
    },
    [BRUSH_SIZES_NAME.medium]: {
        key: BRUSH_SIZES_KEY.medium,
        rate: BRUSH_SIZES_RATE.medium,
        innerRadius: BRUSH_SIZES_INNER_RADIUS.medium,
        outerRadius: BRUSH_SIZES_OUTER_RADIUS.medium,
    },
    [BRUSH_SIZES_NAME.large]: {
        key: BRUSH_SIZES_KEY.large,
        rate: BRUSH_SIZES_RATE.large,
        innerRadius: BRUSH_SIZES_INNER_RADIUS.large,
        outerRadius: BRUSH_SIZES_OUTER_RADIUS.large,
    },
} as const;

export const BRUSH_SIZES_EVENT = {
    "1": { key: BRUSH_SIZES_KEY.xtiny, name: BRUSH_SIZES_NAME.xtiny, action: "currentSize" },
    "2": { key: BRUSH_SIZES_KEY.tiny, name: BRUSH_SIZES_NAME.tiny, action: "currentSize" },
    "3": { key: BRUSH_SIZES_KEY.small, name: BRUSH_SIZES_NAME.small, action: "currentSize" },
    "4": { key: BRUSH_SIZES_KEY.medium, name: BRUSH_SIZES_NAME.medium, action: "currentSize" },
    "5": { key: BRUSH_SIZES_KEY.large, name: BRUSH_SIZES_NAME.large, action: "currentSize" },
} as const;

export const TERRAIN_TOOLS = {
    ocean: { elevation: -0.25 },
    shallow: { elevation: -0.05 },
    valley: { elevation: +0.05 },
    mountain: { elevation: +1.0 },
    vaillage: { elevation: +0.5 },
    deleteVaillage: { elevation: -0.5 },
    clearVaillage: { elevation: -0.5 },
};

export const MAP_CONFIG = {
    // 画布大小
    CANVAS_SIZE: 256,

    // 高程范围
    ELEVATION: {
        MIN: -1.0, // 水面最低高度
        MAX: 1.0, // 陆地最高高度
        WATER_THRESHOLD: 0.0, // 水陆分界线
    },

    // 山地生成参数
    MOUNTAIN: {
        ELEVATION_MULTIPLIER: 5.0, // 山地高度乘数
        STRENGTH_THRESHOLD: 0.5, // 山地强度阈值
        MAX_ELEVATION: 10.0, // 山地最大高度
    },
} as const;

export const NOISE_CONFIG = {
    PERSISTENCE: 0.5, // 持久性是用于控制噪声图的平滑度和细节的参数。
    OCTAVES: 5, // 噪声图的频率数量
    MOUNTAIN_NOISE_OFFSET: {
        X: 30, // 山地在X轴的整体偏移
        Y: 50, // 山地在Y轴的整体偏移
    },
    MOUNTAIN_NOISE_SCALE: {
        X: 33, // 山地在X轴的缩放因子
        Y: 55, // 山地在Y轴的缩放因子
    },
} as const;

export enum VillageActionType {
    ADD = "add",
    DELETE = "delete",
    NONE = "none",
}
