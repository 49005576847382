import { useEffect, useState } from "react";
import { useMount } from "ahooks";
import { Button, Modal } from "antd";

import { SCENE_LIST } from "./const";
import { sortByIndex } from "@/pages/ShowCases/config";
import ImageCarousel from "@/pages/ShowCases/ImageCarousel";

import "./index.css";

import { MapOption } from "@/components/MapSelector";

interface MapResetModalProps {
    visible: boolean;
    onGenerate: () => void;
    onTownSceneChange?: (id: string) => void;
    onCancel: () => void;
}

const TownSceneSelectionModal = ({
    visible,
    onGenerate,
    onTownSceneChange,
    onCancel,
}: MapResetModalProps) => {
    const [selectedTown, setSelectedTown] = useState<MapOption>({
        id: "12",
        cover: "",
        title: "",
        media: [],
        index: 0,
    });
    const [carouselKey, setCarouselKey] = useState<number>(0);

    useMount(() => {
        const list = sortByIndex(SCENE_LIST);

        if (list && list.length > 0) {
            const firstItem = list[0];
            setSelectedTown({
                id: firstItem.id,
                cover: firstItem.cover,
                title: firstItem.title,
                media: firstItem.media,
                index: 0,
            });
        }
    });

    useEffect(() => {
        if (visible) {
            const defaultScene = SCENE_LIST.find((scene) => scene.id === "12");
            if (defaultScene) {
                setSelectedTown({
                    ...defaultScene,
                    index: Number(defaultScene.index),
                });
                setCarouselKey((prev) => prev + 1);
                if (onTownSceneChange) {
                    onTownSceneChange(defaultScene.id);
                }
            }
        }
    }, [visible]);
    return (
        <Modal
            className="town-scene"
            title="Select a town scene for your map"
            visible={visible}
            onCancel={onCancel}
            footer={[
                <div key="footer" className="flex w-full justify-end">
                    <Button key="generate" type="primary" onClick={onGenerate}>
                        OK
                    </Button>
                </div>,
            ]}
        >
            <div className="mt-8 flex gap-4">
                {SCENE_LIST.map((scene) => (
                    <div
                        key={scene.id}
                        className={`card flex cursor-pointer flex-col items-center border-2 ${
                            selectedTown.id === scene.id ? "border-white-400" : "border-transparent"
                        }`}
                        onClick={() => {
                            setSelectedTown({
                                ...scene,
                                index: Number(scene.index),
                            });
                            setCarouselKey((prev) => prev + 1);
                            if (onTownSceneChange) {
                                onTownSceneChange(scene.id);
                            }
                        }}
                    >
                        <img src={scene.cover} alt={scene.title} />
                        <p className="text-center">{scene.title}</p>
                    </div>
                ))}
            </div>

            <div
                className={`mt-8 flex max-h-[1400px] w-full flex-1 flex-col items-center justify-center rounded-md bg-[#191919] text-[#5E5E5E] custom:max-h-[1500px]`}
            >
                {selectedTown.media && (
                    <ImageCarousel key={carouselKey} images={selectedTown.media} />
                )}
            </div>
        </Modal>
    );
};

export default TownSceneSelectionModal;
