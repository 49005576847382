export const exampleSentences = [
    "Peninsula with forests and wetlands, framed by tall mountains",
    "Bay with low hills and dense wetlands",
    "Lake surrounded by mountains and Gobi desert terrain",
    "Coastline with flat, forested wetlands",
    "Canyon with rivers and desert oases",
    "Tundra with frozen lakes, snowy peaks, and sparse vegetation",
    "Desert with sand dunes, sparse plants, and rocky areas",
    "Coastal plain with sandy beaches and mangroves",
    "Snowy mountains with glaciers and alpine meadows",
    "Fertile fields with rolling hills and scattered woodlands",
    "Green landscape with wildflowers and dense forest patches",
    "Archipelago with lush islands and sandy beaches",
    "Barren coast with lowlands and rugged terrain",
    "Lowland island with flat terrain and calm waters",
    "Highlands with rolling hills and dense woodlands",
    "Wetland coast blending into dense vegetation",
    "Tranquil lake surrounded by tall forest trees",
    "Highlands with rolling hills and sweeping views",
    "Island with rocky terrain and sparse vegetation",
    "Peninsula with wetlands and waterlogged terrain",
    "Serene island with beaches and lush greenery",
    "Wetland nestled within dense woodlands",
    "Coastal landscape where forests meet sandy shores",
    "Barren peninsula with rugged highlands",
    "Island with sandy beaches and a lush forest",
    "Wetland coast transitioning into dense woodland",
    "Rolling hills with wildflowers and meandering streams",
    "Serene lake with calm, reflective waters",
    "Wetland with a lake amid dense woodlands",
    "Island with sandy beaches and tropical vegetation",
    "Picturesque island with palm trees and turquoise waters",
    "Lowland islands with gentle slopes and shallow waters",
    "Bay with calm waters and rolling hills",
    "Wetlands with lush vegetation and gentle slopes",
    "Peninsula with sandy beaches and rocky shores",
    "Expansive lowlands with rolling terrain",
    "Highland archipelago with wooded islands and narrow straits",
    "Wetlands merging with brackish waters and rolling terrain",
    "Peninsula with forests and tranquil wetlands",
    "Tundra with snowy plains and frozen lakes"
];