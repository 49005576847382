function GenerateBtn({ text, onClick, isLoading, disabled }: {
    text: string,
    onClick: () => void,
    isLoading: boolean,
    disabled?: boolean
}) {
    return (
        <div className="generate-style flex items-center rounded-md p-5 justify-end">
            <button
                className={`button-3d-preview rounded-md text-base ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={onClick}
                disabled={disabled || isLoading}
            >
                {isLoading ? <p className="flex items-center">
                    <span className="mr-2">Generating...</span>
                    <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                </p> : text}
            </button>
        </div>
    )
}

export default GenerateBtn;