import { TerrainSizeProps } from "@/types";

import "./TerrainSize.css";

import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import questionLineIcon from "@/assets/mapgen/questionLine.svg";
import resetIcon from "@/assets/mapgen/reset.svg";
import TerrainAdjustment from "@/components/TerrainAdjustment";

interface TerrainSizeOption {
    size: number;
    label: string;
    iconSize: string; // 用于控制图标大小的类名
}

function TerrainSize(
    {
        isDisable,
        serialNumber,
        callback,
        options = [
            { size: 500, label: "500 * 500m", iconSize: "w-7 h-7" },
            { size: 5000, label: "5000 * 5000m", iconSize: "w-10 h-10" },
        ],
        defaultSize = 5000,
        tourHandler,
        onOptionReset,
    }: TerrainSizeProps & {
        options?: TerrainSizeOption[];
        defaultSize?: number;
        toggleHandler?: () => void;
    },
    ref: React.ForwardedRef<unknown>,
) {
    const [active, setActive] = useState<number>(defaultSize);
    const [showOptions, setShowOptions] = useState(true);
    const is_3d = location.href.includes("create3d") ? true : false;

    useEffect(() => {
        setActive(defaultSize);
    }, [defaultSize]);

    useImperativeHandle(ref, () => ({
        showOption: () => {
            setShowOptions(true);
        },
    }));

    const clickHandler = (size: number) => {
        if (isDisable) return;
        setActive(size);
        callback(size);
    };

    const handleOptionReset = () => {
        onOptionReset?.();
    };

    return (
        <div className="bg rounded-md p-6">
            <div className="mb-3 flex justify-between text-lg">
                <div className="flex">
                    <span className="serial-number mr-2">{serialNumber}</span>
                    {!is_3d ? <h1>Choose map size</h1> : <h1>Advanced Options</h1>}
                </div>
                <div className="right flex">
                    {is_3d && (
                        <div
                            className="reset-btn mr-4 flex items-center"
                            id="silder-reset"
                            onClick={handleOptionReset}
                        >
                            <img src={resetIcon} width="12" className="mr-2" />
                            <span className="text-xs text-white">Reset</span>
                        </div>
                    )}
                    {tourHandler && (
                        <img
                            className="cursor-pointer"
                            onClick={() => tourHandler?.()}
                            src={questionLineIcon}
                            width="20"
                        />
                    )}
                </div>
            </div>
            {!is_3d && (
                <div className="mt-4 flex flex-wrap gap-4">
                    {options.map((option) => (
                        <div
                            key={option.size}
                            className={`terrain-size ${
                                isDisable ? "cursor-not-allowed" : "cursor-pointer"
                            } ${active === option.size ? "active" : ""}`}
                            onClick={() => clickHandler(option.size)}
                        >
                            <div className={`${option.iconSize} mr-2`}></div>
                            <span className="text-xs">{option.label}</span>
                        </div>
                    ))}
                </div>
            )}
            {is_3d && (
                <>
                    <div
                        className="link options-toggle mt-5 flex cursor-pointer items-center space-x-2"
                        onClick={() => {
                            setShowOptions(!showOptions);
                        }}
                    >
                        {/* <span>
              {showOptions ? "Hide advanced options" : "Show advanced options"}
            </span>
            <span className="scale-50">{showOptions ? "▼" : "▶"}</span> */}
                    </div>
                    <TerrainAdjustment
                        showOptions={showOptions}
                        onClose={() => setShowOptions(false)}
                    />
                </>
            )}
        </div>
    );
}

export default forwardRef(TerrainSize);
