import { FC } from 'react';
import { getLocalToken } from "@/lib/utils";
import downloadIcon from "@/assets/download.svg";
import fullScreenIcon from "@/assets/fullscreen.svg";
import fullScreenExitIcon from "@/assets/fullscreen_exit.svg";
import screenshot from '@/assets/screenshot.svg';
import { useNavigate } from 'react-router-dom';

interface UESceneHeaderProps {
  isUeFullScreen: boolean;
  setIsUeFullScreen: (value: boolean) => void;
  closeWS: () => void;
  taskid: string;
  downloadCheck: (taskId: string) => Promise<boolean>;
  fullScreenBtnRef: React.RefObject<HTMLButtonElement>;
  downLoadImgHandler?: () => void;
  isShowDownBtn?: boolean;
}

const UESceneHeader: FC<UESceneHeaderProps> = ({
  isUeFullScreen,
  setIsUeFullScreen,
  closeWS,
  taskid,
  downloadCheck,
  fullScreenBtnRef,
  downLoadImgHandler,
  isShowDownBtn = true
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between pl-4 pt-3 pr-4 pb-3">
      <div className="flex items-center">
        <span className="text-base">3D Scene</span>
      </div>
      <div className="flex items-center">
        <button onClick={closeWS}>
          Close
        </button>
        {isShowDownBtn && (
          <button
            onClick={async () => {
              let check = await downloadCheck(taskid);
            if (check) {
              let url = `/download?env=${import.meta.env.VITE_APP_MODE}&taskid=${taskid}&token=${getLocalToken()}&r=${Math.random()}`
              navigate(url);
            }
          }}
          className="ml-4"
        >
            <img src={downloadIcon} alt="" />
          </button>
        )}
        <button
          className="ml-4"
          onClick={() => {
            downLoadImgHandler?.()
          }}>
          <img src={screenshot} alt="screenshot" />
        </button>
        <button ref={fullScreenBtnRef} onClick={() => setIsUeFullScreen(!isUeFullScreen)} className="outline-none">
          <img src={isUeFullScreen ? fullScreenExitIcon : fullScreenIcon} alt="" className="h-5 ml-4" />
        </button>
      </div>
    </div>
  );
};

export default UESceneHeader;