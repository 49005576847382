import { useEffect, useRef, useState } from "react";
import { message, Modal, Pagination, Space } from "antd";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

import TaskCard from "@/components/TaskCard/TaskCard";
import { reverseSort } from "@/lib/utils";
import { getTaskList, getVillageFile, removeTask } from "@/service/taskService";
import { MapStyleTerrain, mapStyleTown, Task } from "@/types";

import "./MyScenes.css";

import WaitList from "@/components/WaitList";
import { useMapgenInfo } from "@/pages/Mapgen/hooks/useMapgenInfo";

function MyScenes() {
    const navigate = useNavigate();
    const taskList = useRef<Array<Task>>();
    const [tasks, setTasks] = useState<Array<Task>>([]);
    const [pageNum, setPageNum] = useState<number>(20);
    const [modal, contextHolder] = Modal.useModal();
    const [showModal, setShowModal] = useState(0);
    const { saveMapgenInfo } = useMapgenInfo();

    useEffect(() => {
        getTasks();
    }, []);

    const getTasks = async () => {
        try {
            let response: AxiosResponse = await getTaskList();
            let sortTasks: Array<Task> = reverseSort(response.data.tasks);
            taskList.current = sortTasks;
            let tasks = taskList.current.slice(0, 20);
            setTasks(tasks);
            localStorage.setItem("task_length", tasks.length.toString());
        } catch (error: any) {
            if (error.status === 403) {
                setShowModal(showModal + 1);
            } else {
                message.error(
                    "Failed to get the task list or failed to get the village coordinates.",
                );
            }
        }
    };

    const viewHandle = (task_id: string, status: number) => {
        if (status === 241 || status === 242) {
            message.warning("Model generation failed and cannot be previewed!");
            return;
        }
        localStorage.setItem("task_id", task_id);
        const navigationParams = {
            param: "myscenes",
            task_id: task_id,
        };
        getVillageFile(task_id)
            .then((res) => {
                console.log({ res: res.data });

                // 创建反向映射
                const terrainKeyByValue = Object.entries(MapStyleTerrain).reduce(
                    (acc, [key, value]) => ({ ...acc, [value]: key }),
                    {} as Record<number, string>,
                );

                const townKeyByValue = Object.entries(mapStyleTown).reduce(
                    (acc, [key, value]) => ({ ...acc, [value]: key }),
                    {} as Record<number, string>,
                );
                // 定义村庄类型映射
                const villageTypeMap: Record<string, string> = {
                    '12': 'Willowstead',
                    '21': 'Golden Oasis',
                    '9': 'Meadowstead'
                };

                // 获取第一个村庄的类型（如果存在）
                const villageType = res.data.villages && res.data.villages.length > 0
                    ? villageTypeMap[res.data.villages[0].id] || ''
                    : '';

                if (res.data.map_type === 2) {
                    const { map_style_terrain, map_size, constraints, position, seed, sliders } =
                        res.data;

                    const data = {
                        task_id: task_id,
                        size: map_size,
                        data: constraints,
                        terrain: map_style_terrain,
                        sliders: sliders,
                        position: position,
                        seed: seed,
                    };
                    saveMapgenInfo(data);
                    Object.assign(navigationParams, {
                        from: "mapgen",
                        size: res.data.map_size,
                        biome: terrainKeyByValue[res.data.map_style_terrain],
                        town: townKeyByValue[res.data.map_style_town],
                        villageType: villageType,
                    });
                } else {
                    Object.assign(navigationParams, {
                        from: "myscenes",
                        size: res.data.map_size || 1000,
                        villageType: res.data.villages,
                    });
                }

                navigate("/home/preview", { state: navigationParams });
            })
            .catch((error) => {
                Object.assign(navigationParams, {
                    from: "myscenes",
                    size: 1000,
                });
                navigate("/home/preview", { state: navigationParams });
                console.log(error);
            });
    };

    const pageChangeHandle = async (page: number, pageSize: number) => {
        setPageNum(pageSize);
        let taskslist = taskList.current?.slice((page - 1) * pageSize, pageSize * page);

        if (taskslist) {
            setTasks(taskslist);
        }
    };

    const deleteItem = (task_id: string) => {
        confirm(task_id);
    };

    const confirm = (task_id: string) => {
        modal.confirm({
            title: "Confirm Delete",
            content: "Are you sure you want to delete this item? This action cannot be undone.",
            okText: "Confirm",
            cancelText: "Cancel",
            onOk: async () => {
                try {
                    let response = await removeTask(task_id);
                    if (response.data.result === 1) {
                        const newTasks = tasks.filter((task) => task.task_id !== task_id);
                        setTasks(newTasks);
                        const tasklist = taskList.current?.filter(
                            (task) => task.task_id !== task_id,
                        );
                        taskList.current = tasklist;
                    } else {
                        message.warning("Deletion Failed!");
                    }
                } catch (error) {
                    console.log(error);
                    message.warning("Deletion Failed!");
                }
            },
            onCancel: () => {
                console.log("cancel");
            },
        });
    };

    return (
        <div className="h-full text-white">
            <div className="flex flex-wrap p-4">
                <Space size={[15, 15]} wrap>
                    {tasks.length > 0 &&
                        tasks.map((task) => (
                            <TaskCard
                                key={task.task_id}
                                status={task.status}
                                taskID={task.task_id}
                                imgSrc={
                                    import.meta.env.VITE_APP_BASE_URL +
                                    import.meta.env.VITE_APP_ASSET_FILE +
                                    task.task_id +
                                    "/mini_map.png"
                                }
                                viewClick={() => viewHandle(task.task_id, task.status)}
                                deleteClick={() => deleteItem(task.task_id)}
                                coord={task.coord}
                                renderType={task.render_type}
                                pageType="myscenes"
                            />
                        ))}
                </Space>
            </div>
            <div className="flex justify-start pb-6">
                <Pagination
                    defaultCurrent={1}
                    total={taskList.current?.length}
                    defaultPageSize={20}
                    pageSize={pageNum}
                    onChange={pageChangeHandle}
                    className="custom-pagination"
                />
            </div>
            <WaitList isOpen={showModal} />
            {contextHolder}
        </div>
    );
}
export default MyScenes;
