import LabsCard from "@/components/LabsCard";

import "./Labs.css";

import { useHideUnity } from "./hooks/useHideUnity";

interface LabsCardData {
    title: string;
    description: string;
    imageUrl: string;
    routePath: string;
    type: "map" | "layout";
    routeConfig?: {
        [x in string]: string;
    };
}

const labsData: LabsCardData[] = [
    {
        title: "2D colormap",
        description: "Generate your terrain map from text and colormap",
        imageUrl: "/image/2d-colormap.png",
        type: "map",
        routePath: "/labs/colormap",
    },
    {
        title: "Text to 3D Map",
        description: "Generate map with text input",
        imageUrl: "/image/text-to-map.png",
        type: "map",
        routePath: "/labs/create3d",
    },
    {
        title: "Tag to 2D/3D Map",
        description: "Control the map with tags",
        imageUrl: "/image/tag-to-map.png",
        type: "map",
        routePath: "/labs/tag",
    },
    {
        title: "Tag to Village Layout",
        description: "Control the village layout with tags",
        imageUrl: "/image/tag-to-layout.png",
        type: "layout",
        routePath: "/labs/village",
    },
    {
        title: "Mask to Village Layout",
        description: "Generate the village layout with drawing masks",
        imageUrl: "/image/mask-to-layout_02.png",
        type: "layout",
        routePath: "/labs/mask",
    },
    {
        title: "Text to Village Layout",
        description: "Generate map with text input",
        imageUrl: "/image/mask-to-layout.png",
        type: "layout",
        routePath: "/labs/textlayout",
    },
];

const unityLabsData: LabsCardData[] = [
    {
        title: "Scene in Unity",
        description: "Generate your 3D scene in the Unity engine",
        imageUrl: "/image/mask-to-unity.png",
        type: "map",
        routePath: "/home/create3d?source=labs",
    },
];

// 添加分类辅助函数
const categorizeLabsData = (cards: LabsCardData[]) => {
    return {
        maps: cards.filter((card) => card.type.toLowerCase().includes("map")),
        layouts: cards.filter((card) => card.type.toLowerCase().includes("layout")),
    };
};

function Labs() {
    const userEmail = localStorage.getItem("email");
    const hideUnityLabs = useHideUnity();

    if (!userEmail) {
        return (
            <div className="flex h-full items-center justify-center">
                Please login to access this page
            </div>
        );
    }

    const { maps, layouts } = categorizeLabsData(
        hideUnityLabs ? labsData : [...labsData, ...unityLabsData],
    );

    return (
        <div className="flex flex-col pb-20">
            <div className="flex">
                <h3 className="p-0 text-6xl font-thin">LABS. CYBEVER</h3>
                <p className="ml-5 flex items-end text-base text-gray-400">
                    Experiment with the future of 3D creation
                </p>
            </div>

            {/* Maps Section */}
            <div className="mt-10">
                <h4 className="mb-5 text-xl">Map Generation</h4>
                <div className="flex flex-wrap gap-5">
                    {maps.map((card, index) => (
                        <LabsCard
                            key={`map-${index}`}
                            title={card.title}
                            description={card.description}
                            imageUrl={card.imageUrl}
                            routePath={card.routePath}
                            routeConfig={card.routeConfig}
                        />
                    ))}
                </div>
            </div>

            {/* Layouts Section */}
            <div className="mt-10">
                <h4 className="mb-5 text-xl">Layout Generation</h4>
                <div className="flex flex-wrap gap-5">
                    {layouts.map((card, index) => (
                        <LabsCard
                            key={`layout-${index}`}
                            title={card.title}
                            description={card.description}
                            imageUrl={card.imageUrl}
                            routePath={card.routePath}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Labs;
