// hooks/useMapgenState.ts
import { useEffect, useState } from "react";
import { message } from "antd";

import { MAP_ZOOM, MAP_ZOOM_SIZE, ZOOM_DEFAULT } from "@/pages/Mapgen/constants";
import { MapgenConfig } from "@/pages/Mapgen/type";
import MapGen from "@/mapgen";
import { DEFAULT_MAPGEN_SELECT } from "@/pages/Mapgen/components/MapgenTool/constants";
import { MapgenSelect, MapgenTypeEnums } from "@/types";

export const useMapgenState = () => {
    const [mapgen, setMapgen] = useState<MapGen | null>(null);
    const [renderer, setRenderer] = useState<any>(null);
    const [brush, setBrush] = useState<any>(null);

    const [zoom, setZoom] = useState<number>();
    const [terrain, setTerrain] = useState<number>(0);
    const [terrainsize, setTerrainSize] = useState<number>(2500);
    const [mapgenSelect, setMapgenSelect] = useState<Partial<MapgenSelect>>(DEFAULT_MAPGEN_SELECT);
    const [messageApi, contextHolder] = message.useMessage();

    // 初始化地图
    const initMapgen = async (
        mapInfo: {
            size: number;
            constraints: any;
            terrain: number;
            sliders?: any;
            position?: any;
            seed?: number;
        },
        setShowTips: (value: boolean) => void,
        userData: any
    ) => {
        const { size, constraints, terrain, sliders, position, seed } = mapInfo;
        const zoom = MAP_ZOOM[size] || ZOOM_DEFAULT;

        setZoom(zoom);

        setTerrainSize(size);
        setTerrain(terrain);

        const mapgenConfig: MapgenConfig = {
            initiate: {
                position,
                size,
            },
            render: {
                zoom: zoom,
                terrain: terrain,
                position: position,
            },
            painting: {
                size: 256,
                seed: seed,
                paintingElevation: constraints,
                brush: {
                    type: "moutain",
                    size: "small",
                },
                undo: {
                    undoEl: "undo",
                    redoEl: "redo",
                    onUndoCallback: () => {
                        messageApi.open({
                            type: "warning",
                            content:
                                " This action cannot be undone. You are already at the earliest step.",
                        });
                    },
                    onRedoCallback: () => {
                        messageApi.open({
                            type: "warning",
                            content:
                                "This action cannot be redone. You are already at the latest step.",
                        });
                    },
                },
            },
            operaters: {
                supports: [
                    {
                        type: "zoom",
                        el: "#zoom-slider",
                        zoomSizeDisplayEl: ".zoom-size-display",
                        zoomStep: 0.1,
                        range: [0.5, 5],
                    },
                    {
                        type: "pan",
                        el: "pan-map",
                    },
                ],
            },
        };

        const mapgenInstance = new MapGen("mapgen4", mapgenConfig);
        // window.mapgen = mapgenInstance;height_map (2)

        mapgenInstance.onReady().then(() => {
            // if (Object.keys(constraints).length > 0 || seed) {
            //     mapgenInstance.start({ seed });
            // } else {
            //     mapgenInstance.start();
            // }
            mapgenInstance.start({ constraints: userData });
            const painting = mapgenInstance.getPainting();
            const renderer = mapgenInstance.getRender();
            const brush = painting.getBrush();
            const plugins = mapgenInstance.getPlugins();

            setRenderer(renderer);
            setBrush(brush);
            setMapgen(mapgenInstance);

            renderer.setZoom(zoom);

            //开启绘画
            painting.start((message: string) => {
                messageApi.open({
                    type: "warning",
                    content: message,
                });
            });

            //设置高级选项初始值
            if (sliders) {
                plugins.get("sliders")?.setAllValue(sliders);
            }
            // //设置中心位置
            if (position) {
                const { x, y } = position;
                renderer.setPosition(-x, -y);
            }
            //设置当前笔刷大小
            brush.setSizeConfigByZoom(zoom);

            //设置地形
            renderer.setTerrain(terrain);
            mapgenInstance.setEditMode(false);

            //设置ZOOM变化时回调函数
            renderer.onZoomChange = (zoom) => {
                setMapgenSelect({
                    type: !mapgenInstance.isEdit()
                        ? ("pan" as MapgenTypeEnums)
                        : (brush.getTool() as MapgenTypeEnums),
                    vaillage: !mapgenInstance.isEdit()
                        ? ("pan" as MapgenTypeEnums)
                        : (brush.getTool() as MapgenTypeEnums),
                    //@ts-ignore
                    size: brush?.getSizeConfigByZoom(zoom),
                });

                // 零时修改
                if (
                    zoom < 0.416 ||
                    (zoom > 1.04 && painting.vaillageManager.getVaillage().length > 0)
                ) {
                    setShowTips(true);
                } else {
                    setShowTips(false);
                }
            };

            setMapgen(mapgenInstance);
            setMapgenSelect({
                type: !mapgenInstance.isEdit()
                    ? ("pan" as MapgenTypeEnums)
                    : (brush.getTool() as MapgenTypeEnums),
                vaillage: !mapgenInstance.isEdit()
                    ? ("pan" as MapgenTypeEnums)
                    : (brush.getTool() as MapgenTypeEnums),
                //@ts-ignore
                size: brush.getSize(),
            });
        });
    };

    //非房屋则删除安全遮罩
    useEffect(() => {
        if (mapgenSelect.vaillage !== MapgenTypeEnums.vaillage) {
            mapgen?.getPainting().vaillageManager.hideSafeZoneMask();
        }
    }, [mapgenSelect]);

    // 处理地形大小变化
    const handleTerrainSize = async (size: number) => {
        if (Object.keys(MAP_ZOOM).includes(size.toString())) {
            const zoom = MAP_ZOOM[size];
            setZoom(zoom);
            renderer?.setZoom(zoom);
            brush.setSizeConfigByZoom(zoom);

            if (mapgenSelect.size !== MAP_ZOOM_SIZE[size]) {
                setMapgenSelect((preVal) => ({
                    ...preVal,
                    size: MAP_ZOOM_SIZE[size],
                }));
            }
        }
    };

    // 重新加载地图
    const reloadMap = () => {
        mapgen?.reload();
    };

    //选择笔刷之后，默认选中之前的地形
    // useEffect(() => {
    //     setMapgenSelect({
    //         type: mapgen?.getPainting().getBrush().getTool() as MapgenTypeEnums,
    //         size: mapgenSelect.size,
    //     });
    // }, [mapgenSelect.size]);

    return {
        mapgen,
        zoom,
        terrain,
        terrainsize,
        mapgenSelect,
        contextHolder,
        initMapgen,
        handleTerrainSize,
        reloadMap,
        setMapgenSelect,
        setTerrain,
    };
};
