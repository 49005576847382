import { useState } from 'react';
import { useMount } from 'ahooks';
import { getTaskDownloadInfo } from '@/service/taskService';
import './index.css';


const Download = () => {
    const [meshChecked, setMeshChecked] = useState(false);
    const [levelChecked, setLevelChecked] = useState(false);
    const [downloadVisible, setDownloadVisible] = useState(true);
    const [taskInfo, setTaskInfo] = useState<any>(null);
    // 获取URL参数
    const getQueryString = () => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get("taskid");
        const token = params.get("token");
        const env = params.get("env");
        return { taskid: id, token, env };
    };

    const { token, taskid, env } = getQueryString();

    useMount(() => {
        taskid && getTaskDownloadInfo(taskid).then((res) => {
            setTaskInfo(res.data);
        });
    });

    // 处理Mesh下载链接
    const getMeshDownloadUrl = () => {
        return meshChecked
            ? taskInfo?.ue_project_link
            : "#";
    };

    // 处理Level下载
    const handleLevelDownload = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!levelChecked) {
            e.preventDefault();
            return;
        }

        const baseUrl = env === 'stage'
            ? 'https://auto-3d-server-stage.oixm.cn'
            : `https://auto-3d-server-${env}.cybever.ai`;

        const downloadUrl = `${baseUrl}/asset/download/uelevel/${taskid}?token=${token}`;

        setDownloadVisible(false);
        setTimeout(() => setDownloadVisible(true), 40000);

        window.location.href = downloadUrl;
    };

    return (
        <div className='download-page'>
            <header className='download-header pb-2 mb-5'>
                <h2 className='m-0 text-3xl'>Download</h2>
                <p className=' text-white opacity-70'>Download speeds vary - please check your browser for the ETA.</p>
            </header>
            <main className='download-main'>
                <section>
                    <h3 className='p-0 text-2xl'>Download UE5 files</h3>
                    <p>
                        <span>Unreal Engine version：</span>
                        <span>{taskInfo?.ue_version}</span>
                    </p>
                    <p>
                        <span>Estimated file size：</span>
                        <span>{taskInfo?.ue_project_size}</span>
                    </p>
                    <p>
                        <span>Applicable version：</span>
                        <span>{taskInfo?.applicable_version}</span>
                    </p>
                    <p className="notice" style={{ display: 'none' }}>
                        Version update detected.
                        Please download the Mesh and texture pack to ensure the final effect.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <input
                            type="checkbox"
                            id="confirm-mesh"
                            checked={meshChecked}
                            onChange={(e) => setMeshChecked(e.target.checked)}
                        />
                        <label htmlFor="confirm-mesh" className='mx-2'>
                            I confirm I have purchased the license of all the assets listed in
                        </label>
                        <a href="https://docs.google.com/spreadsheets/d/1L_ZTD14oElKY7KacqoVzy_eZ4fytC4IBqD2kcWlkzIM/edit?gid=1741287951#gid=1741287951"
                            target="_blank"
                            className='a-link'
                            rel="noopener noreferrer">
                            this file.
                        </a>
                    </p>
                    <p>
                        <a
                            href={getMeshDownloadUrl()}
                            className={`download ${!meshChecked ? 'disabled' : ''}`}
                        >
                            Download
                        </a>
                    </p>
                </section>

                <section>
                    <h3 className='p-0 text-2xl'>Level File</h3>
                    <p>
                        <span>Estimated file size：</span>
                        <span>{taskInfo?.ue_level_size}</span>
                    </p>
                    <p>
                        <span>Applicable version：</span>
                        <span>{taskInfo?.applicable_version}</span>
                    </p>
                    <p id="task">TaskID： {taskid}</p>
                    <p>&nbsp;</p>
                    <p>
                        <input
                            type="checkbox"
                            id="confirm-level"
                            checked={levelChecked}
                            onChange={(e) => setLevelChecked(e.target.checked)}
                        />
                        <label htmlFor="confirm-level" className='mx-2'>
                            I confirm I have purchased the license of all the assets listed in
                        </label>
                        <a href="https://docs.google.com/spreadsheets/d/1L_ZTD14oElKY7KacqoVzy_eZ4fytC4IBqD2kcWlkzIM/edit?gid=1741287951#gid=1741287951"
                            target="_blank"
                            rel="noopener noreferrer"
                            className='a-link'
                        >
                            this file.
                        </a>
                    </p>
                    <p>
                        <a
                            href="#"
                            onClick={handleLevelDownload}
                            className={`download ${!levelChecked ? 'disabled' : ''}`}
                            style={{ visibility: downloadVisible ? 'visible' : 'hidden' }}
                        >
                            Download
                        </a>
                    </p>
                </section>
            </main>
            <div className="how mt-10">
                <a href="https://www.cybever.ai/import-in-engine"
                    target="_blank"
                    rel="noopener noreferrer"
                    className='a-link'
                >
                    How to import the file
                </a>
            </div>
        </div>
    );
};

export default Download;