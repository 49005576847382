interface MapPreviewProps {
    mapURL: string;
    size: number;
    from?: string;
    biome?: string;
    task_id: string;
    villageType: string;
}

const MapPreview = ({ mapURL, size, from, biome, task_id, villageType }: MapPreviewProps) => {
    return (
        <>
            <div className="relative flex justify-between">
                {mapURL && <img src={mapURL} alt="" width={502} height={502} />}
            </div>
            <div>
                <ul className="mt-5 text-sm text-white opacity-50">
                    <li>Size: {size}</li>
                    {from === "mapgen" ? (
                        <>
                            <li>Biome: {biome}</li>
                        </>
                    ) : null}
                    <li>Task: {task_id}</li>
                    {villageType && <li>Village: {villageType}</li>}
                </ul>
            </div>
        </>
    );
};

export default MapPreview;
