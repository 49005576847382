import checkmark from "@/assets/checkmark.png";

interface StepTwoProps {
    isLoading: boolean;
    roadMap: any;
    handleStepOne: () => void;
    handleStepTwo: () => void;
    buttonClassName: string;
    isRestart: React.MutableRefObject<boolean>;
}

function StepTwo({ isLoading, roadMap, handleStepOne, handleStepTwo, buttonClassName, isRestart }: StepTwoProps) {
    return (
        <>
            {!roadMap.image && <div className="bg-white text-black/40 text-center w-[600px] h-[600px] flex items-center justify-center">Please draw the boundary of the town first</div>}
            {roadMap.image && <img src={`data:image/png;base64,${roadMap.image}`} alt="boundary" width={600} height={600} />}
            <div className="flex justify-between mt-4">
                <button
                    onClick={() => {
                        isRestart.current = true;
                        handleStepOne();
                    }}
                    disabled={isLoading}
                    className={buttonClassName}
                >
                    {isLoading && isRestart.current ? 'Generating...' : 'Restart'}
                </button>
                <button
                    onClick={handleStepTwo}
                    disabled={isLoading}
                    className={`flex items-center ${buttonClassName}`}
                >
                    {!isLoading && <img src={checkmark} alt="checkmark" width={14} height={14} />}
                    <span className="ml-2">{isLoading && !isRestart.current ? 'Generating preview...estimated time 2 min' : 'Next step'}</span>
                </button>
            </div>
        </>
    );
}

export default StepTwo;