function StepFour({ usdzResult }: { usdzResult: string[] }) {
    return (
        <a
            href={usdzResult?.[2]}
            target='_blank'
            className="border border-[#323232] rounded-md px-4 py-2 hover:text-link text-center"
        >
            Open 3D City Viewer
        </a>
    );
}

export default StepFour; 