// @ts-nocheck
import param from "../../config/index.ts";
import { VillageActionType } from "./const.ts";
import villageIcon from "@/assets/mapgen/scene1.png";
import { Pos } from "@/types";

interface VaillageManagerContext {
    onWarning?: (message: string) => void;
    elevation: Float32Array;
    generate: () => void;
    addVillageIcon: (x: number, y: number) => void;
    deleteVillageIcon: (id: string) => void;
    screenToWorld: (coords: [number, number]) => vec2;
    isEdit: () => boolean;
    getZoom: () => number;
    sliders: Sliders;
}

class VaillageManager {
    private mapEl: HTMLElement;
    private vaillages: Array<Pos> = [];
    private elevation: Float32Array;
    private canvas: HTMLCanvasElement;
    private safeZoneRadius: number;
    private villageId: string;
    private originalElevations: { index: number; height: number }[] = [];
    private context: VaillageManagerContext;

    public init(mapEl: HTMLElement, canvas: HTMLCanvasElement, context: VaillageManagerContext) {
        this.mapEl = mapEl;
        this.canvas = canvas;

        this.context = context;
        this.elevation = context.elevation;
        // 设置安全区域半径（以相对值表示，范围0-1）
        this.safeZoneRadius = 0.8; // 可以根据需要调整这个值
    }

    // 添加创建遮罩的方法
    public createSafeZoneMask() {
        let mask = document.querySelector(".safe-zone-mask");
        if (!mask) {
            mask = document.createElement("div");
            mask.setAttribute("class", "safe-zone-mask");
            mask.style.position = "absolute";
            mask.style.width = "100%";
            mask.style.height = "100%";
            mask.style.top = "0";

            mask.style.background = `
                linear-gradient(to right, 
                    rgba(0, 0, 0, 0.2) ${(1 - this.safeZoneRadius) * 50}%,
                    transparent ${(1 - this.safeZoneRadius) * 50}%,
                    transparent ${(1 + this.safeZoneRadius) * 50}%,
                    rgba(0, 0, 0, 0.2) ${(1 + this.safeZoneRadius) * 50}%
                ),
                linear-gradient(to bottom, 
                    rgba(0, 0, 0, 0.2) ${(1 - this.safeZoneRadius) * 50}%,
                    transparent ${(1 - this.safeZoneRadius) * 50}%,
                    transparent ${(1 + this.safeZoneRadius) * 50}%,
                    rgba(0, 0, 0, 0.2) ${(1 + this.safeZoneRadius) * 50}%
                )
            `;
            mask.style.zIndex = "1000";
            mask.style.pointerEvents = "none";
            this.mapEl.appendChild(mask);
        }
    }

    public hideSafeZoneMask() {
        const mask = document.querySelector(".safe-zone-mask");
        if (mask) {
            mask.remove();
        }
    }

    private flattenTerrainAroundVillage(vid: string, x: number, y: number) {
        // this.elevation = this.context.getElevation();
        if (!this.elevation) return;
        const mapSize = Math.sqrt(this.elevation.length);

        // 使用 screenToWorld 转换坐标
        const worldCoords = this.context.screenToWorld([x, y]);
        const centerX = Math.floor(worldCoords[0]);
        const centerY = Math.floor(worldCoords[1]);

        // 村庄影响半径（在世界坐标系统中）
        const radius = 60;
        const originalHeights: { index: number; height: number }[] = [];
        const elevation = new Float32Array(this.elevation);
        if (!this.originalElevations.length) {
            this.originalElevations = elevation;
        }
        //this.elevation = this.originalElevations;
        for (let dy = -radius; dy <= radius; dy++) {
            for (let dx = -radius; dx <= radius; dx++) {
                const currentX = centerX + dx;
                const currentY = centerY + dy;
                // 添加噪声到距离计算中
                const noiseOffset = (Math.random() - 0.5) * 15; // 随机偏移量 ±7.5
                const distanceFromCenter =
                    Math.sqrt(Math.pow(currentX - centerX, 2) + Math.pow(currentY - centerY, 2)) +
                    noiseOffset;

                // 使用更大的噪声值来创造不规则边缘
                const edgeNoise = Math.random() * 20 - 10; // ±10 的随机值

                const distance = Math.sqrt(dx * dx + dy * dy);

                if (
                    distance <= radius + edgeNoise &&
                    currentY >= 0 &&
                    currentY < 1000 &&
                    currentX >= 0 &&
                    currentX < 1000
                ) {
                    const index =
                        Math.floor((currentY / 1000) * mapSize) * mapSize +
                        Math.floor((currentX / 1000) * mapSize);
                    // 确保 index 不会超出范围
                    if (index >= 0 && index < mapSize * mapSize) {
                        const originalHeight = this.elevation[index];

                        originalHeights.push({
                            index,
                            height: originalHeight,
                        });
                        const normalizedDistance = distanceFromCenter / radius;
                        const transitionFactor = 1 - Math.pow(normalizedDistance, 2);

                        const noise = Math.random() * 15;
                        if (distance <= 20 + noise) {
                            // 内圆区域（半径20以内）保持固定高度0.2
                            this.elevation[index] = 0.25 + (Math.random() - 0.5) * 0.05;
                        } else {
                            // 外圈区域（20到radius之间）进行平滑过渡
                            const transitionDistance = (distance - 20) / (radius - 20); // 重新计算过渡距离
                            const transitionNoise = (Math.random() - 0.5) * 0.1;
                            if (originalHeight > 0.5) {
                                // 如果原始高度大于0.5，平滑降低到0.2
                                this.elevation[index] =
                                    originalHeight * transitionDistance +
                                    (0.2 + transitionNoise) * (1 - transitionDistance);
                            } else {
                                // 如果原始高度小于等于0.5，平滑上升到0.1
                                this.elevation[index] =
                                    originalHeight * transitionDistance +
                                    (0.1 + transitionNoise) * (1 - transitionDistance);
                            }
                        }
                    }
                }
            }
        }
        this.context.generate({ constraints: this.elevation });
    }

    public addVillageHandler = (event: MouseEvent) => {
        event.stopPropagation();
        if (!this.context.isEdit()) return;
        const actionType = this.context.getVillageActionType();
        if (actionType === VillageActionType.DELETE || actionType === VillageActionType.NONE)
            return;

        if (this.vaillages.length >= 1) {
            this.context.onWarning?.("Up to 1 town locations can be added.");
            return;
        }

        if (this.context.getZoom() > 1.04) {
            this.context.onWarning?.(
                "The map size is too small, which may cause town generation to fail",
            );
            return;
        }

        const bounds = this.mapEl.getBoundingClientRect();

        const coords = [
            (event.clientX - bounds.left) / bounds.width,
            (event.clientY - bounds.top) / bounds.height,
        ];

        // 检查是否在安全区域内
        const centerX = 0.5;
        const centerY = 0.5;
        const distance = Math.max(Math.abs(coords[0] - centerX), Math.abs(coords[1] - centerY));

        if (distance > this.safeZoneRadius / 2) {
            // 除以2是因为safeZoneRadius是相对于整个地图的比例
            this.context.onWarning?.("Villages can only be built within safe areas.");
            return;
        }

        let out = this.context.screenToWorld(coords);

        const vid = Date.now().toString();

        this.elevation = new Float32Array(this.context.getElevation());
        // 添加平地化处理
        const originalHeights = this.flattenTerrainAroundVillage(vid, coords[0], coords[1]);
        this.context.addVillageIcon(vid, out[0], out[1]);

        //零时方案，添加村庄的时候，河流减小
        param.rivers.flow = 0.02;
        this.context.generate();

        this.vaillages.push({ vid, id: this.villageId, x: out[0] / 1000, y: out[1] / 1000 });
    };

    public enableVillageRemoval = (event: MouseEvent) => {
        event.stopPropagation();
        if (!this.context.isEdit()) return;

        const bounds = this.mapEl.getBoundingClientRect();

        const coords = [
            (event.clientX - bounds.left) / bounds.width,
            (event.clientY - bounds.top) / bounds.height,
        ];

        // // 使用 screenToWorld 转换为世界坐标
        const worldCoords = this.context.screenToWorld(coords);
        const clickWorldX = worldCoords[0] / 1000; // 归一化到 0-1 范围
        const clickWorldY = worldCoords[1] / 1000;

        let rotation = this.context.sliders.getValue("rotate_deg") || 0;
        if (rotation < 0) rotation = 180 - rotation;
        const rotationRad = rotation * (Math.PI / 180); // 转换为弧度

        // 检查是否点击在现有村庄范围内
        const existingVillage = this.vaillages.find((village) => {
            //如果用世界坐标没有必要用转化角度
            const x = village.x - 0.5;
            const y = village.y - 0.5;

            // 应用旋转
            let rotatedX = x * Math.cos(rotationRad) - y * Math.sin(rotationRad);
            let rotatedY = x * Math.sin(rotationRad) + y * Math.cos(rotationRad);

            // 转换回0-1范围
            rotatedX = rotatedX + 0.5;
            rotatedY = rotatedY + 0.5;

            // 确保值在0-1范围内
            rotatedX = Math.max(0, Math.min(1, rotatedX));
            rotatedY = Math.max(0, Math.min(1, rotatedY));

            return this.isClickInVillageRange(clickWorldX, clickWorldY, village.x, village.y);
        });

        if (existingVillage) {
            this.removeVillage(existingVillage);
        }
    };

    public setVillageId(id: string) {
        this.villageId = id;
    }

    // 添加新方法检查点击位置是否在村庄影响圈内
    private isClickInVillageRange(
        clickX: number,
        clickY: number,
        villageX: number,
        villageY: number,
    ): boolean {
        const bounds = this.mapEl.getBoundingClientRect();
        const radius = 60 / bounds.width; // 转换为相对坐标

        const distance = Math.sqrt(Math.pow(clickX - villageX, 2) + Math.pow(clickY - villageY, 2));
        return distance <= radius;
    }

    private removeVillage(villageItem: { vid: string; x: number; y: number }, random? = false) {
        // if (!this.context.isEdit()) return;
        const actionType = this.context.getVillageActionType();
        if (actionType === VillageActionType.ADD || actionType === VillageActionType.NONE) return;

        this.context.deleteVillageIcon(villageItem.vid);
        this.vaillages = this.vaillages.filter((v) => v.vid !== villageItem.vid);
        //零时方案，删除村庄的时候，河流增加
        param.rivers.flow = 0.2;
        this.context.generate({ constraints: this.originalElevations });

        if (!random) {
            const newElevation = new Float32Array(this.originalElevations);
            this.elevation = newElevation;
        } else {
            this.originalElevations = [];
        }
    }

    public clear({ random = false }: { random?: boolean } = {}) {
        // 清除所有村庄数据
        this.context.setVillageActionType(VillageActionType.DELETE);
        this.vaillages.forEach((village) => {
            this.removeVillage(village, random);
        });
        this.hideSafeZoneMask();
    }

    public getVaillage(): Array<Pos> {
        return this.vaillages;
    }
}

export default new VaillageManager();
