// @ts-nocheck
//react
import { useEffect, useRef, useState } from "react";
import { useMount, useUnmount } from "ahooks";
//components
import { message } from "antd";
//utils
import cx from "classnames";
//

import { useNavigate } from "react-router-dom";

import MapgenTool from "./components/MapgenTool";
import MapResetModal from "./components/moveConfirmModal";
//constants
import { MapSize, ZOOM_DEFAULT } from "./constants.ts";
import { useMapgenGenerate } from "./hooks/useMapgenGenerate";
import { useMapgenInfo } from "./hooks/useMapgenInfo";
// hooks
import { useMapgenState } from "./hooks/useMapgenState";
import { useMapgenTour } from "./hooks/useMapgenTour";
import BlockRouter from "@/components/blockRouter";
import MapgenTour from "@/components/MapgenTour";
import PreparedScene from "@/components/PreparedScene";
import RandomImage from "@/components/RandomImage/RandomImage";
import TerrainSize from "@/components/TerrainSize/TerrainSize";
import TextToMap from "@/components/TextTomap/TextToMap";
import Warning from "@/components/Warning/Warning";
import param from "@/mapgen/config/index.ts";
import { exampleSentences } from "@/pages/LabText/data";
import { DEFAULT_MAPGEN_SELECT } from "@/pages/Mapgen/components/MapgenTool/constants.ts";
//service
import {
    createTask,
    getTextToMapPlatform,
    getVillageFile,
    revisionTask,
} from "@/service/taskService";

import "./Mapgen.css";

//type
import { MapFiles, MapgenConfig, MapgenStorageData, MapInfo, TaskResponse } from "./type";
//img
import backIcon from "@/assets/back.png";
import { MapgenTypeEnums } from "@/types";

function Mapgen() {
    const navigate = useNavigate();
    const {
        mapgen,
        zoom,
        terrain,
        terrainsize,
        mapgenSelect,
        contextHolder,
        initMapgen,
        handleTerrainSize,
        reloadMap,
        setMapgenSelect,
        setTerrain,
    } = useMapgenState();
    const {
        openTour,
        setOpenTour,
        currentTour,
        setCurrentTour,
        step1Ref,
        step2Ref,
        step3Ref,
        step4Ref,
        step5Ref,
        tourHandler,
    } = useMapgenTour();
    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get("from"); // 获取 'from' 参数的值
    const source = queryParams.get("source"); // 获取 'source' 参数的值
    const { getTaskIdFromLocal, clearLocal, saveMapgenInfo, getMapInfo } = useMapgenInfo();
    const { isGenerateLoading, baseImg, hideRedMap, generate, checkAndHandleMapRotation } =
        useMapgenGenerate(mapgen, getMapInfo, source);

    const [mapResetModalVisible, setMapResetModalVisible] = useState<boolean>(false);
    const [canvasImage, setCanvasImage] = useState<string>("");
    const [showWarning, setShowWarning] = useState(false);
    const [showTips, setShowTips] = useState(false);
    const [showGenWarning, setShowGenWarning] = useState(false);
    const [activeTab, setActiveTab] = useState<"random" | "text">("random");
    const [showTabWarning, setShowTabWarning] = useState<boolean>(false);
    const titleRef = useRef<string>("");
    const [showBiomeWarning, setShowBiomeWarning] = useState<boolean>(false);
    let mapInfo: MapInfo = {
        size: MapSize.DEFAULT,
        constraints: {},
        terrain: 0,
        sliders: {},
        seed: 0,
        position: {
            x: 500,
            y: 500,
        },
        zoom: ZOOM_DEFAULT,
    };

    useMount(async () => {
        initializeMapData();
    });

    useUnmount(() => {
        mapgen?.dispose();
    });

    const initializeMapData = async () => {
        const shouldClearTask =
            ["home"].includes(from as string) || ["header"].includes(from as string);
        let taskId = getTaskIdFromLocal();

        if (shouldClearTask && taskId) {
            clearLocal();
            taskId = null;
        }
        // 初始化地图信息
        let currentMapInfo = { ...mapInfo }; // 使用默认值

        if (taskId) {
            try {
                currentMapInfo = await getMapInfo(taskId);
            } catch (error) {
                console.error("Failed to fetch map info:", error);
                clearLocal(); // 加载失败时清除本地存储
            }
        }

        await initMapgen(currentMapInfo, setShowTips);
    };

    // 处理随机按钮点击事件
    const clickRandomBtn = async () => {
        const hasVillage = mapgen?.getPainting().vaillageManager.getVaillage().length > 0;
        if (
            mapgen?.getPainting().getUserHasPainted() ||
            localStorage.getItem("taskId") ||
            hasVillage
        ) {
            setShowWarning(true);
        } else {
            mapgen?.reload();
        }
    };

    // 处理地形大小变化
    const terrainSizeHandler = async (size: number) => {
        await handleTerrainSize(size);
    };

    const generateHandler = async (terrain: number) => {
        setTerrain(terrain);
        const taskId = localStorage.getItem("taskId");
        const { needConfirm, resetImage, noNeedGenerate } =
            await checkAndHandleMapRotation(setShowGenWarning);

        if (noNeedGenerate) {
            return;
        }

        if (needConfirm) {
            setMapResetModalVisible(true);
            setCanvasImage(resetImage);
        } else {
            await generate(taskId, clearLocal);
        }
    };

    const onTownSceneChange = (id: string, title: string) => {
        mapgen?.getRender().setVaillageTexture(id);
        mapgen?.getPainting().vaillageManager.setVillageId(id);
        mapgen?.getPainting().vaillageManager.createSafeZoneMask();
    };

    // 处理文本生成地图
    const clickTextToMap = async (text: string) => {
        const BIOME_TO_TERRAIN = {
            "forest wetland": 0,
            "snow mountain": 1,
            "gobi desert": 2,
        };

        const res = await getTextToMapPlatform({ text, output_dim: 256 });

        if (res.data.biome) {
            const biome = res.data.biome;
            const render = mapgen?.getRender();
            const zoomOpe = mapgen?.getMapOperations().getZoom();

            // 使用映射关系设置对应的地形值，如果没有匹配则默认使用0
            const terrainValue = BIOME_TO_TERRAIN[biome] ?? 0;
            setTerrain(terrainValue);
            mapgen?.start({ constraints: JSON.parse(res.data.mapdata) });

            render.setZoom(0.416);
            zoomOpe.setSliderValue(2.5);
            mapgen?.getRender().setTerrain(terrainValue);
        }
    };

    // 切换到随机地图 重置地图
    const resetMap = () => {
        mapgen?.clearConstraints();
        mapgen?.getPainting().vaillageManager.clear();
        mapgen?.reload();
        mapgen?.getPlugins().get("sliders")?.setAllValue({
            flow: 0.2,
            hill_height: 0,
            island: 0.5,
            lg_river_width: 3,
            mountain_height: 60,
            mountain_sharpness: 11.5,
            tilt_deg: 0,
            rotate_deg: 0,
        });
        setTerrain(0);
        mapgen?.getRender().setTerrain(0);

        mapgen?.getPainting().undoManager.reset();

        mapgen?.getRender().setZoom(0.416);
        mapgen?.getMapOperations().getZoom().setSliderValue(2.5);
        mapgen?.setEditMode(false);
    };

    const handleTabChange = (newTab: "random" | "text") => {
        if (newTab !== activeTab) {
            setShowTabWarning(true);
        }
    };

    const handleTabConfirm = (newTab: "random" | "text") => {
        setActiveTab(newTab);
        resetMap();
        setShowTabWarning(false);
    };

    return (
        <>
            <MapgenTour
                openTour={openTour}
                setOpenTour={setOpenTour}
                currentTour={currentTour}
                step1Ref={step1Ref}
                step2Ref={step2Ref}
                step3Ref={step3Ref}
                step4Ref={step4Ref}
                step5Ref={step5Ref}
                setCurrentTour={setCurrentTour}
            />
            <div className="pb-6">
                <div>
                    <div className="flex items-center">
                        <BlockRouter
                            clickHandler={() => {
                                navigate("/", { replace: true });
                            }}
                        >
                            <section className="flex cursor-pointer items-center">
                                <img src={backIcon} alt="back" width={14} height={14} />
                                <span className="ml-2 text-link">Back</span>
                            </section>
                        </BlockRouter>
                        <h2 className="ml-10">Create a new scene</h2>
                    </div>
                </div>
                <div className="mt-4 flex flex-col sm:flex-row">
                    <div className="flex-1 space-y-4">
                        <div ref={step1Ref}>
                            <div className="mapgen-tab-headers relative">
                                <button
                                    className={`mapgen-tab-button relative z-10 ${
                                        activeTab === "random"
                                            ? "rounded-t-md border border-b-0 border-white/20 bg-[rgb(37,53,58)]"
                                            : "border-transparent"
                                    }`}
                                    onClick={() => handleTabChange("random")}
                                >
                                    Random Map
                                </button>
                                <button
                                    className={`mapgen-tab-button relative z-10 ${
                                        activeTab === "text"
                                            ? "rounded-t-md border border-b-0 border-white/20 bg-[rgb(37,53,58)]"
                                            : "border-transparent"
                                    }`}
                                    onClick={() => handleTabChange("text")}
                                >
                                    Text to Map
                                </button>
                                <div className="absolute bottom-0 left-0 w-full border-b border-white/20"></div>
                            </div>
                            <div className="mapgen-tab-content">
                                {activeTab === "random" ? (
                                    <RandomImage
                                        serialNumber={1}
                                        isDisable={false}
                                        clickRandomBtn={clickRandomBtn}
                                        tourHandler={() => tourHandler(0)}
                                        clickImg={() => {}}
                                    />
                                ) : (
                                    <TextToMap
                                        serialNumber={1}
                                        clickCallback={clickTextToMap}
                                        title="Generate your map with text"
                                        exampleWords={exampleSentences}
                                    />
                                )}
                            </div>
                        </div>

                        <div ref={step2Ref}>
                            <TerrainSize
                                serialNumber={2}
                                isDisable={false}
                                callback={terrainSizeHandler}
                                options={[
                                    { size: 500, label: "500 * 500m", iconSize: "w-6 h-6" },
                                    { size: 750, label: "750 * 750m", iconSize: "w-7 h-7" },
                                    { size: 1000, label: "1000 * 1000m", iconSize: "w-8 h-8" },
                                    { size: 5000, label: "5000 * 5000m", iconSize: "w-9 h-9" },
                                ]}
                                defaultSize={terrainsize}
                                tourHandler={() => tourHandler(1)}
                                onOptionReset={() => {
                                    mapgen?.getPlugins().get("sliders")?.setAllValue({
                                        flow: 0.2,
                                        hill_height: 0,
                                        island: 0.5,
                                        lg_river_width: 3,
                                        mountain_height: 60,
                                        mountain_sharpness: 11.5,
                                    });
                                }}
                            />
                        </div>

                        <div ref={step4Ref}>
                            <PreparedScene
                                actionRef={step5Ref}
                                serialNumber={3}
                                clickImgHandler={generateHandler}
                                onTerrainChange={(terrain: number) => {
                                    if (
                                        terrain !== 0 &&
                                        mapgen?.getPainting().vaillageManager.getVaillage().length >
                                            0
                                    ) {
                                        setShowBiomeWarning(true);
                                    } else {
                                        mapgen?.getRender().setTerrain(terrain);
                                    }
                                    setTerrain(terrain);
                                }}
                                terrain={terrain}
                                tourHandler={() => tourHandler(3)}
                            />
                        </div>
                    </div>
                    <div
                        id="ui"
                        className="board flex w-[642px] max-w-[90rem] flex-col text-sm sm:ml-6"
                    >
                        <div className="flex flex-col" ref={step3Ref}>
                            <div id="map" className={cx("relative overflow-hidden")}>
                                <canvas
                                    id="mapgen4"
                                    width="2048"
                                    height="2048"
                                    className={cx({
                                        "!absolute bottom-full left-full": hideRedMap,
                                    })}
                                />

                                <img
                                    src={baseImg}
                                    className={cx("w-full", { "!hidden": !hideRedMap })}
                                />
                                <div
                                    className={"zoom-slider-container"}
                                    style={{
                                        position: "absolute",
                                        zIndex: 10,
                                        bottom: "10px",
                                        right: "10px",
                                        pointerEvents: "none", // 允许事件穿透
                                        backgroundColor: "transparent", // 确保背景透明
                                    }}
                                >
                                    <input
                                        className="zoom-slider"
                                        type="range"
                                        name=""
                                        id="zoom-slider"
                                        max="5"
                                        min="0.5"
                                        step="0.1"
                                        style={{ pointerEvents: "auto" }} // 允许滑块本身接收事件
                                    />

                                    <div
                                        className={cx("zoom-size-display", {
                                            "red-text": showTips,
                                        })}
                                        style={{ pointerEvents: "auto" }}
                                    >
                                        size: 5 * 5 km
                                    </div>
                                </div>
                            </div>

                            <div className={cx("tips mt-4", { "tips-hidden": !showTips })}>
                                {mapgen?.renderer.getZoom() >= 1.04
                                    ? "The map size is too small, which may cause town generation to fail"
                                    : "The map size exceeds the 2.5km limit and cannot be generated"}
                            </div>

                            <MapgenTool
                                mapgen={mapgen}
                                mapgenSelect={mapgenSelect}
                                setMapgenSelect={setMapgenSelect}
                                onTownSceneChange={onTownSceneChange}
                                activeTab={activeTab}
                                terrain={terrain}
                            />
                        </div>

                        {/* <div id="sliders" className="">
                            <button id="button-reset">Reset</button>
                          </div> */}
                    </div>
                </div>
                {contextHolder}
                <Warning
                    isVisible={showWarning}
                    text="Your current map edits will be lost. Do you want to proceed with creating a new map?"
                    onCancel={() => setShowWarning(false)}
                    onContinue={() => {
                        mapgen?.reload();

                        if (mapgen?.getPainting().vaillageManager.getVaillage().length > 0) {
                            mapgen?.getPainting().vaillageManager.clear({ random: true });
                        }
                        mapgen?.setEditMode(false);
                        setMapgenSelect({
                            type: MapgenTypeEnums.pan,
                            vaillage: undefined,
                            size: MapgenTypeEnums.medium,
                        });
                        setShowWarning(false);
                    }}
                />
                <Warning
                    isVisible={showGenWarning}
                    text=" The map size exceeds the 2.5km limit and cannot be generated. Please adjust the scale slider to reduce the size."
                    onCancel={() => setShowGenWarning(false)}
                />
                <Warning
                    isVisible={showTabWarning}
                    text="Your current map edits will be lost. Do you want to proceed with switching tabs?"
                    onCancel={() => {
                        setShowTabWarning(false);
                    }}
                    onContinue={() => {
                        const newTab = activeTab === "random" ? "text" : "random";
                        handleTabConfirm(newTab);
                    }}
                />

                <Warning
                    isVisible={showBiomeWarning}
                    text="Other biomes currently do not support adding villages.
                     Replacing the biome will remove any villages that have already been placed. Do you wish to continue?"
                    onCancel={() => {
                        setShowBiomeWarning(false);
                        setTerrain(0);
                        mapgen?.getRender().setTerrain(0);
                    }}
                    onContinue={() => {
                        setTimeout(() => {
                            mapgen?.getPainting().vaillageManager.clear();
                            setTerrain(terrain);
                            mapgen?.getRender().setTerrain(terrain);
                            mapgen?.setEditMode(false);
                            setShowBiomeWarning(false);
                            setMapgenSelect({
                                type: MapgenTypeEnums.pan,
                                vaillage: undefined,
                                size: MapgenTypeEnums.medium,
                            });
                        }, 100);
                    }}
                />
                <MapResetModal
                    visible={mapResetModalVisible}
                    priviewEl={canvasImage}
                    onCancel={() => {
                        setMapResetModalVisible(false);
                    }}
                    onLoading={isGenerateLoading}
                    onGenerate={() => generate(localStorage.getItem("taskId"))}
                />
            </div>
        </>
    );
}
export default Mapgen;
