import { useState, useEffect } from "react";
import RadioGroup from "../RadioGroup";
import TagGroup from "../TagGroup";
import TagDropDown from "../TagDropDown";


interface TagSelectorProps {
    serialNumber: string;
    isDisable: boolean;
    isLoading: boolean;
    generateHandler: (data: {
        road: {
            radio: string;
            tags: string[];
        };
        water: string;
        villageDensity: string;
        villageShape: string;
        farmland: {
            radio: string;
            tags: string[];
        };
        mapSize: string;
    }) => void;
}


interface OptionType {
    value: string;
    tooltip: string;
}

const roadOptions: OptionType[] = [
    { value: 'Isolated', tooltip: 'Only trails' },
    { value: 'Crossroad', tooltip: 'Crossroads or T-junction' },
    { value: 'Highway', tooltip: 'A wide road running through the village' },
    { value: 'Dead end', tooltip: 'Highway ends at a village sqaure' },
];

const roadTagOptions: OptionType[] = [
    { value: 'no Square', tooltip: 'No village square (unavailable for deadend)' },
    { value: 'Organic', tooltip: 'Wobbly roads' },
];

const waterOptions: OptionType[] = [
    { value: 'None', tooltip: 'No water' },
    { value: 'Coast', tooltip: 'Costal village' },
    { value: 'River', tooltip: 'Riverside village' },
    { value: 'Island', tooltip: 'Island village' },
    { value: 'Pond', tooltip: 'Small pond at the center' },
    { value: 'Confluence', tooltip: 'Two rivers flowing together' },
    { value: 'Estuary', tooltip: 'Mouth of a river' },
];

const villageDensityOptions: OptionType[] = [
    { value: 'Dense', tooltip: 'Densely built village' },
    { value: 'Sparse', tooltip: 'Sparsely built village' },
];

const villageShapeOptions: OptionType[] = [
    { value: 'District', tooltip: 'village is part of a larger settlement' },
    { value: 'Palisade', tooltip: 'A palisade/wall surrounds the village' },
];

const farmlandOptions: OptionType[] = [
    { value: 'Farmland', tooltip: 'Many farm fields' },
    { value: 'no Orchards', tooltip: 'No orchards' },
    { value: 'Uncultivated', tooltip: 'No farm fields' },
];

const farmlandTagOptions: OptionType[] = [
    { value: 'Grove', tooltip: 'Trees grow thickly around the buildings' },
];

const mapSizeOptions = [
    { value: '300', label: 'Small 300 * 300 px' },
    { value: '500', label: 'Medium 500 * 500 px' },
    { value: '800', label: 'Large 800 * 800 px' },
];

function TagSelector({
    serialNumber,
    isDisable,
    isLoading,
    generateHandler
}: TagSelectorProps) {
    const [selectedRoad, setSelectedRoad] = useState<string[]>([]);
    const [selectedRoadRadio, setSelectedRoadRadio] = useState<string>('Isolated');
    const [selectedWater, setSelectedWater] = useState<string>('');
    const [selectedVillageDensity, setSelectedVillageDensity] = useState<string>('');
    const [selectedVillageShape, setSelectedVillageShape] = useState<string>('');
    const [selectedFarmlandRadio, setSelectedFarmlandRadio] = useState<string>('');
    const [selectedFarmland, setSelectedFarmland] = useState<string[]>([]);
    const [selectedMapSize, setSelectedMapSize] = useState<string>('300');


    const handleRoadRadioChange = (value: string) => {
        setSelectedRoadRadio(value);
    };

    const handleRoadOptionChange = (env: string, checked: boolean) => {
        if (checked) {
            setSelectedRoad(prev => [...prev, env]);
        } else {
            setSelectedRoad(prev => prev.filter(item => item !== env));
        }
    };

    const handleFarmlandRadioChange = (value: string) => {
        setSelectedFarmlandRadio(value);
    };

    const handleFarmlandOptionChange = (env: string, checked: boolean) => {
        if (checked) {
            setSelectedFarmland(prev => [...prev, env]);
        } else {
            setSelectedFarmland(prev => prev.filter(item => item !== env));
        }
    };

    const handleGenerate = () => {
        const selectedData = {
            road: {
                radio: selectedRoadRadio,
                tags: selectedRoad
            },
            water: selectedWater,
            villageDensity: selectedVillageDensity,
            villageShape: selectedVillageShape,
            farmland: {
                radio: selectedFarmlandRadio,
                tags: selectedFarmland
            },
            mapSize: selectedMapSize
        };
        generateHandler(selectedData);
    };

    const handleReset = () => {
        setSelectedRoad([]);
        setSelectedRoadRadio('Isolated');
        setSelectedWater('');
        setSelectedVillageDensity('');
        setSelectedVillageShape('');
        setSelectedFarmlandRadio('');
        setSelectedFarmland([]);
        setSelectedMapSize('300');
    };


    const handleTagDropDownChange = (value: string | number) => {
        setSelectedMapSize(value as string);
    };

    useEffect(() => {
        if (selectedWater === 'Island') {
            setSelectedRoadRadio('Isolated');
            setSelectedRoad([]);
        }
    }, [selectedWater]);

    useEffect(() => {
        console.log('selectedRoadRadio changed:', selectedRoadRadio);
        if (selectedRoadRadio === 'Dead end') {
            setSelectedRoad(prev => prev.filter(option => option !== 'no Square'));
        }
    }, [selectedRoadRadio]);

    return (
        <div className="random p-5 rounded-md">
            <div className="flex text-lg mb-3">
                <span className="serial-number mr-2">{serialNumber}</span>
                <h1>Generate your village with tags</h1>
            </div>
            <div className="text-sm">
                <TagGroup
                    label="Road *"
                    options={roadTagOptions}
                    selectedOptions={selectedRoad}
                    selectedRadio={selectedRoadRadio}
                    radioOptions={roadOptions}
                    isLoading={isLoading}
                    onRadioChange={handleRoadRadioChange}
                    onOptionChange={handleRoadOptionChange}
                    disabled={selectedWater === 'Island'}
                    disabledOptions={selectedRoadRadio === 'Dead end' ? ['no Square'] : []}
                />

                <RadioGroup
                    label="Water"
                    options={waterOptions}
                    selected={selectedWater}
                    disabled={isLoading}
                    onChange={setSelectedWater}
                />

                <div className="flex gap-10">
                    <RadioGroup
                        label="Village density"
                        options={villageDensityOptions}
                        selected={selectedVillageDensity}
                        disabled={isLoading}
                        onChange={setSelectedVillageDensity}
                    />

                    <RadioGroup
                        label="Village shape"
                        options={villageShapeOptions}
                        selected={selectedVillageShape}
                        disabled={isLoading}
                        onChange={setSelectedVillageShape}
                    />
                </div>
                <TagGroup
                    label="Farmland & forest"
                    options={farmlandTagOptions}
                    radioOptions={farmlandOptions}
                    selectedOptions={selectedFarmland}
                    selectedRadio={selectedFarmlandRadio}
                    isLoading={isLoading}
                    onRadioChange={handleFarmlandRadioChange}
                    onOptionChange={handleFarmlandOptionChange}
                />

            </div>

            <TagDropDown 
                options={mapSizeOptions} 
                onChange={handleTagDropDownChange}
                isDisable={isLoading}
                value={selectedMapSize}
            />

            <div className="flex justify-between w-full">
                <button
                    className="text-white opacity-50 mr-4 hover:opacity-100"
                    onClick={handleReset}
                    disabled={isLoading}
                >
                    Reset
                </button>
                <section className="flex items-end">
                    <button
                        className={`random-btn mt-4 px-8 py-1 ${!isDisable && !isLoading
                            ? "hover:bg-[rgba(255,255,255,0.2)] cursor-pointer"
                            : "cursor-not-allowed opacity-20"
                            }`}
                        disabled={isDisable || isLoading}
                        onClick={handleGenerate}
                    >
                        {isLoading ? (
                            <div className="flex items-center">
                                <span className={isLoading ? 'opacity-50' : ''}>Generating</span>
                                <svg
                                    className="animate-spin ml-2 h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                </svg>
                            </div>
                        ) : "Generate Map"}
                    </button>
                </section>
            </div>
        </div>
    );
}

export default TagSelector;