import { AxiosResponse } from "axios";

import request from "@/lib/http";
import { labsMapRequest, labsRequest } from "@/lib/labsHttp";
import generateMapHttp from "@/lib/mapHttp";

// 上传图片创建新任务
async function createTask(formData: FormData, map_type: number): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_CREATE_TASK + `?map_type=${map_type}`;
    return request({ method: "post", url, data: formData });
}

// 同一张图片修改时，不创建新的任务
function revisionTask(formData: FormData, taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_REVISION_TASK + taskID;
    return request({ method: "post", url, data: formData });
}

// 查询任务的状态
function taskStatus(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_STATUS + taskID;
    return request.get(url);
}

// 确定任务CONFIRM
function confirmTask(taskID: string, render_type?: number): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_CONFIRM_TASK + taskID;
    return request.get(url, {
        params: {
            render_type: render_type,
        },
    });
}

// 关闭任务
function closeTask(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_CLOSE_TASK + taskID;
    return request.get(url);
}

// 获取任务列表
function getTaskList(): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_LIST;
    return request.get(url);
}

// 获取villagefile
function getVillageFile(taskid: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_ASSET_FILE + taskid + "/data.json";
    return request.get(url);
}

// 删除任务
function removeTask(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_DELETE + taskID;
    return request.get(url);
}

// 获取任务列表
function taskRender(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_RENDER + taskID;
    return request.get(url);
}

// 获取模型的url
function getModelURL(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_ASSET_MODEL + taskID + "/scene.fbx";
    return request.get(url);
}

// 获取模型的url
function randomMap(): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_RANDOM_MAP;
    return generateMapHttp.get(url, { responseType: "arraybuffer" });
}

type WaitingList = {
    name: string;
    organization_name: string;
    memo: string;
    role: string;
};

function saveWaitList(data: WaitingList): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_JOIN_NOW;
    return request({
        method: "post",
        url,
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
    });
}

function checkWaitList(): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_WAITUSER_CHECK;
    return request(url);
}

// 获取模型的url
function getMapByText(text: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_GENERATE_BY_TEXT;
    return generateMapHttp({
        method: "post",
        url,
        data: {
            prompt: text,
        },
    });
}

// labs/tag-to-map/color-map 生成颜色图
function gen2DMap(tags: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_LABS_MAP_TAG_TO_MAP_COLOR_MAP + "?tags=" + tags;
    return labsMapRequest.get(url, {
        responseType: "blob", // 添加 responseType 为 blob
    });
}

// labs/tag-to-map/map-gen 生成3D图
function gen3DMap(data: any): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_LABS_MAP_TAG_TO_MAP_MAP_GENERATE;
    return labsMapRequest({
        method: "post",
        url,
        data: data,
        responseType: "blob",
    });
}

// labs/mask-upload 上传mask
function uploadMask(data: any): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_LABS_MASK_UPLOAD;
    return labsRequest({
        method: "post",
        url,
        data: data,
    });
}

// labs/generate_road_map 生成道路图
function generateRoadMap(data: any): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_LABS_GENERATE_ROAD_MAP;
    return labsRequest({
        method: "post",
        url,
        data: data,
    });
}

// labs/flux/create_task 创建Flux任务
function createFluxTask(data: any): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_LABS_FLUX_CREATE_TASK;
    return labsRequest({
        method: "post",
        url,
        data: data,
    });
}

// labs/flux/result 获取Flux任务结果
function getFluxResult(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_LABS_FLUX_RESULT + "?prompt_id=" + taskID;
    return labsRequest.get(url);
}

// labs/clipseg 获取clipseg任务结果
function getClipsegResult(data: any): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_LABS_CLIPSEG;
    return labsRequest({
        method: "post",
        url,
        data: data,
    });
}

// labs/convert_city_layout 转换城市布局
function convertCityLayout(data: any): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_LABS_CONVERT_CITY_LAYOUT;
    return labsRequest({
        method: "post",
        url,
        data: data,
    });
}

// labs/generate_usdz 生成usdz
function generateUSDZ(data: any): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_LABS_GENERATE_USDZ;
    return labsRequest({
        method: "post",
        url,
        data: data,
    });
}

function getUserInfo(): Promise<AxiosResponse<any, any>> {
    const url = import.meta.env.VITE_APP_CHECK_LOGIN;
    return request.get(url);
}

// 获取showcase列表
function getShowcaseList(): Promise<AxiosResponse<any, any>> {
    const url = import.meta.env.VITE_APP_LABS_SHOWCASE_LIST;
    return request.get(url);
}

// 获取showcase详情
function createShowcaseTask(id: string): Promise<AxiosResponse<any, any>> {
    const url = import.meta.env.VITE_APP_LABS_SHOWCASE_TASK_CREATE + "?showcase_id=" + id;
    return request.get(url);
}

// 获取showcase列表
function getTaskDownloadInfo(task_id: string): Promise<AxiosResponse<any, any>> {
    const url = import.meta.env.VITE_APP_TASK_DOWNLOAD_INFO + task_id;
    return request.get(url);
}

function getTextToMap(data: any): Promise<AxiosResponse<any, any>> {
    const url = import.meta.env.VITE_APP_LABS_TEXT_TO_MAP;
    return labsMapRequest({
        method: "post",
        url,
        data: data,
    });
}

function getVillageLayout(tags: string, mapSize: string): Promise<AxiosResponse<any, any>> {
    const url =
        import.meta.env.VITE_APP_LABS_VILLAGE_LAYOUT +
        "?tags=" +
        tags +
        "&width=" +
        mapSize +
        "&height=" +
        mapSize;
    return labsMapRequest.get(url);
}

// labs/village/textgenerate 生成村庄布局
function generateVillageLayout(data: any): Promise<AxiosResponse<any, any>> {
    const url = import.meta.env.VITE_APP_LABS_VILLAGE_TEXT_GENERATE;
    return labsMapRequest({
        method: "post",
        url,
        data: data,
    });
}

function getTextToMapPlatform(data: any): Promise<AxiosResponse<any, any>> {
    const url = import.meta.env.VITE_APP_LABS_MAP_PLATFORM_TEXT_TO_MAP;
    return labsMapRequest({
        method: "post",
        url,
        data: data,
    });
}


// labs/tag-to-map/map-gen 生成3D图
function get3DMapjson(data: any): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_LABS_MAP_TAG_TO_MAP_MAP_GENERATE;
    return labsMapRequest({
        method: "post",
        url,
        data: data,
        //responseType: "blob",
    });
}
export {
    createTask,
    revisionTask,
    taskStatus,
    confirmTask,
    closeTask,
    getTaskList,
    removeTask,
    taskRender,
    getModelURL,
    randomMap,
    getMapByText,
    getVillageFile,
    checkWaitList,
    saveWaitList,
    getUserInfo,
    gen2DMap,
    gen3DMap,
    uploadMask,
    generateRoadMap,
    createFluxTask,
    getFluxResult,
    getClipsegResult,
    convertCityLayout,
    generateUSDZ,
    getShowcaseList,
    createShowcaseTask,
    getTextToMap,
    getVillageLayout,
    generateVillageLayout,
    getTextToMapPlatform,
    get3DMapjson,
    getTaskDownloadInfo
};
