export interface ElevationChange {
    index: number;
    oldValue: number;
    newValue: number;
}

export interface UndoState {
    changes: ElevationChange[];
    timestamp: number;
}

interface Context {
    elevation: Float32Array;
    generate: (options: { constraints?: Float32Array | null }) => void;
    setElevation: (elevation: Float32Array) => void;
    getElevation: () => Float32Array;
}

export default class UndoManager {
    private undoStack: UndoState[] = [];
    private redoStack: UndoState[] = [];
    private maxStates: number = 50;
    private currentChanges: ElevationChange[] = [];
    private initialElevation: Float32Array | null = null;

    constructor(private context: Context) {
        // 绑定按钮点击事件
        document.getElementById("undo")?.addEventListener("click", () => this.undo());
        document.getElementById("redo")?.addEventListener("click", () => this.redo());
        document.getElementById("reset")?.addEventListener("click", () => this.reset());

        document.addEventListener("keydown", (e: KeyboardEvent) => {
            // Ctrl/Cmd + Z: UNDO
            if ((e.ctrlKey || e.metaKey) && e.key === "z" && !e.shiftKey) {
                e.preventDefault();
                this.undo();
            }
            // Ctrl/Cmd + Shift + Z OR  Ctrl/Cmd + Y: REDO
            if ((e.ctrlKey || e.metaKey) && ((e.key === "z" && e.shiftKey) || e.key === "y")) {
                e.preventDefault();
                this.redo();
            }
        });

        // 初始化按钮状态
        this.updateButtonStates();
    }

    public startStroke() {
        // 开始新的笔画时清空当前改变记录
        this.currentChanges = [];
        if (!this.initialElevation) {
            this.initialElevation = new Float32Array(this.context.getElevation());
        }
    }

    public addChange(index: number, oldValue: number, newValue: number) {
        // 只有当值真正改变时才记录
        if (oldValue !== newValue) {
            this.currentChanges.push({ index, oldValue, newValue });
        }
    }

    public endStroke() {
        // 笔画结束时，如果有改变则保存状态
        if (this.currentChanges.length > 0) {
            this.pushState({
                changes: this.currentChanges,
                timestamp: Date.now(),
            });
            this.currentChanges = [];
        }
    }

    private pushState(state: UndoState) {
        this.undoStack.push(state);
        this.redoStack = [];

        if (this.undoStack.length > this.maxStates) {
            this.undoStack.shift();
        }

        this.updateButtonStates();
    }

    public undo(): boolean {
        const state = this.undoStack.pop();
        if (!state) return false;

        this.redoStack.push(state);
        const elevation = new Float32Array(this.context.getElevation());
        // 反向应用变化
        state.changes.forEach((change) => {
            elevation[change.index] = change.oldValue;
        });

        this.context.setElevation(elevation);
        this.updateButtonStates();
        this.context.generate({ constraints: elevation });
        return true;
    }

    public redo(): boolean {
        const state = this.redoStack.pop();
        if (!state) return false;
        const elevation = new Float32Array(this.context.getElevation());
        // 正向应用变化
        state.changes.forEach((change) => {
            elevation[change.index] = change.newValue;
        });
        this.context.setElevation(elevation);

        this.undoStack.push(state);
        this.updateButtonStates();
        this.context.generate({ constraints: elevation });
        return true;
    }

    public reset() {
        this.undoStack = [];
        this.redoStack = [];

        // 更新地图显示
        // this.context.setElevation(this.initialElevation);
        this.context.generate({ constraints: this.initialElevation });
        this.initialElevation = null;
        this.updateButtonStates();
    }

    public getUndoStack() {
        return this.undoStack;
    }

    public getRedoStack() {
        return this.redoStack;
    }

    private updateButtonStates() {
        const undoButton = document.getElementById("undo") as HTMLButtonElement;
        const redoButton = document.getElementById("redo") as HTMLButtonElement;
        const resetButton = document.getElementById("reset") as HTMLButtonElement;

        if (undoButton) {
            undoButton.disabled = this.undoStack.length === 0;
            undoButton.classList.toggle("unavailable", this.undoStack.length === 0);
        }
        if (redoButton) {
            redoButton.disabled = this.redoStack.length === 0;
            redoButton.classList.toggle("unavailable", this.redoStack.length === 0);
        }
        if (resetButton) {
            const resetDisabled = this.undoStack.length === 0 && this.redoStack.length === 0;
            resetButton.disabled = resetDisabled;
            resetButton.classList.toggle("unavailable", resetDisabled);
        }
    }
}
