import { useEffect, useRef, useState } from "react";
import { message } from "antd";
import cx from "classnames";
import { Link } from "react-router-dom";

import MapgenTool from "../Mapgen/components/MapgenTool";
import { MapSize } from "../Mapgen/constants";
import { useMapgenGenerate } from "../Mapgen/hooks/useMapgenGenerate";
import { useMapgenInfo } from "../Mapgen/hooks/useMapgenInfo";
import { useMapgenTour } from "../Mapgen/hooks/useMapgenTour";
import { MapInfo } from "../Mapgen/type";
import { useMapgenState } from "./useMapgenState";
import backIcon from "@/assets/back.png";
import DrawingBoard from "@/components/DrawingBoard/DrawingBoard";
import TagSelector from "@/components/TagSelector";
import Warning from "@/components/Warning/Warning";
import { blobToBase64, convertToString, loadBlobImage } from "@/lib/utils";
import { gen2DMap, get3DMapjson } from "@/service/taskService";
import { DrawBoardRef } from "@/types";

import "./LabTag.css";

let mapInfo: MapInfo = {
    size: MapSize.LARGE,
    constraints: {},
    terrain: 0,
    // @ts-ignore
    sliders: {},
    seed: 0,
    position: {
        x: 0,
        y: 0,
    },
};

const baseButtonClass = "px-4 py-1";
const activeClass = "bg-gradient-to-r from-[#5FB3CE] to-[#132976] text-white";
const inactiveClass = "bg-white bg-opacity-20";
const generateBtnClass =
    "w-full mt-2 bg-gradient-to-r from-[#5FB3CE] to-[#132976] border-white rounded-md py-1 text-white";
// const No3DMapText = "w-[512px] h-[512px] flex items-center justify-center text-white opacity-20";

function LabTag() {
    const drawBoardRef = useRef<DrawBoardRef>(null);
    const [mapType, setMapType] = useState<"2d" | "3d">("2d");
    const [generateLoading, setGenerateLoading] = useState(false);
    const [is3DLoading, setIs3DLoading] = useState(false);
    const [map3dData, setMap3dData] = useState<Array<any>>([]);
    const [img2dUrl, setImg2dUrl] = useState<Blob | null>(null);
    const [warningVisible, setWarningVisible] = useState(false);
    const [selectedData, setSelectedData] = useState<any>(null);

    const { mapgen, initMapgen, mapgenSelect, setMapgenSelect } = useMapgenState();
    const { getMapInfo } = useMapgenInfo();
    const [showTips, setShowTips] = useState(false);
    // @ts-ignore
    const { hideRedMap, baseImg, generate, checkAndHandleMapRotation } = useMapgenGenerate(
        mapgen,
        // @ts-ignore
        getMapInfo,
    );
    const { step3Ref } = useMapgenTour();

    useEffect(() => {
        if (mapType === "3d" && map3dData.length > 0) {
            initializeMapData(map3dData);
        }

        if (mapType === "2d") {
            mapgen?.dispose();
        }

        return () => {
            mapgen?.dispose();
        };
    }, [mapType]);

    const initializeMapData = async (userData: any) => {
        // 初始化地图信息
        let currentMapInfo = { ...mapInfo }; // 使用默认值
        await initMapgen(currentMapInfo, setShowTips, userData);
    };

    // 生成地图
    const generateMap = async (selectedData: any) => {
        if (!selectedData || selectedData.length === 0) {
            message.error("Please select at least one tag");
            return;
        }
        mapgen?.dispose();
        setSelectedData(selectedData);

        if (img2dUrl || map3dData.length > 0) {
            setWarningVisible(true);
            return;
        }

        if (mapType === "2d") {
            await handleGenerate2D(selectedData);
        } else {
            setGenerateLoading(true);
            try {
                const blob = await handleGenerate2D(selectedData);
                const base64 = await blobToBase64(blob);
                await handleGenerate3D(base64);
            } finally {
                setGenerateLoading(false);
            }
        }
    };

    const handleGenerate2D = async (selectedData: any) => {
        try {
            if (mapType === "2d") {
                setGenerateLoading(true);
            }
            let tags = convertToString(selectedData);
            const image = await gen2DMap(tags);
            setImg2dUrl(image.data);

            if (!image || !image.data) {
                message.error("Image generation failed");
                return;
            }

            const imgElement = await loadBlobImage(image.data);
            drawBoardRef.current?.boardDrawImage(imgElement);
            drawBoardRef.current?.addChooseImage();

            return image.data;
        } catch (err) {
            console.error("Generation error:", err);
            message.error("An error occurred during generation");
            return false;
        } finally {
            if (mapType === "2d") {
                setGenerateLoading(false);
            }
        }
    };

    const handleGenerate3D = async (base64?: string) => {
        setIs3DLoading(true);
        try {
            const data = {
                imgpath: "",
                imgcontent: mapType === "2d" ? drawBoardRef.current?.getImageData() : base64,
                streaming: true,
                output_dim: 256,
            };

            const res = await get3DMapjson(data);
            if (res && res.data) {
                // const base64Data = URL.createObjectURL(res.data);
                // setImg3dUrl(base64Data);
                console.log({ res: JSON.parse(res.data.mapdata) });
                setMapType("3d");
                setMap3dData(JSON.parse(res.data.mapdata));
                initializeMapData(JSON.parse(res.data.mapdata));
            } else {
                message.error("3D image generation failed");
            }
        } catch (err) {
            console.error("3D generation error:", err);
            message.error("An error occurred during 3D generation");
        } finally {
            setIs3DLoading(false);
        }
    };

    const handleWarningCancel = () => {
        setWarningVisible(false);
    };

    const handleWarningContinue = async () => {
        setWarningVisible(false);
        setMap3dData([]);
        setImg2dUrl(null);

        if (mapType === "2d") {
            await handleGenerate2D(selectedData);
        } else {
            setGenerateLoading(true);
            try {
                const blob = await handleGenerate2D(selectedData);
                const base64 = await blobToBase64(blob);
                await handleGenerate3D(base64);
            } finally {
                setGenerateLoading(false);
            }
        }
    };

    const handleViewChange = async (view: "2d" | "3d") => {
        setMapType(view);
        if (view === "2d" && img2dUrl) {
            const imgElement = await loadBlobImage(img2dUrl);
            drawBoardRef.current?.boardDrawImage(imgElement);
            drawBoardRef.current?.addChooseImage();
        }
    };

    // 生成3D地图
    const generateHandler = async () => {
        const taskId = localStorage.getItem("taskId");
        await generate(taskId);
    };

    return (
        <div className="lab-tag">
            <div className="flex items-center">
                <Link to="/labs" className="flex cursor-pointer items-center">
                    <img src={backIcon} alt="back" width={14} height={14} />
                    <span className="ml-2 text-link">Back</span>
                </Link>
                <h2 className="ml-5">Tag to map</h2>
            </div>
            <div className="mt-5 flex">
                <div className="flex flex-1 flex-col">
                    <TagSelector
                        serialNumber="1"
                        isDisable={false}
                        generateHandler={generateMap}
                        isLoading={
                            mapType === "2d" ? generateLoading : generateLoading || is3DLoading
                        }
                    />
                </div>
                <div className="">
                    <div className="board relative sm:ml-6">
                        <div className="mb-2 flex justify-center">
                            <button
                                className={`${baseButtonClass} rounded-l-full ${
                                    mapType === "2d" ? activeClass : inactiveClass
                                }`}
                                onClick={() => handleViewChange("2d")}
                            >
                                2D map
                            </button>
                            <button
                                className={`${baseButtonClass} rounded-r-full ${
                                    mapType === "3d" ? activeClass : inactiveClass
                                }`}
                                onClick={() => handleViewChange("3d")}
                            >
                                3D map
                            </button>
                        </div>
                        {mapType === "2d" ? (
                            <div className="">
                                <DrawingBoard
                                    ref={drawBoardRef}
                                    imageListIndex={() => {}}
                                    disableBrush={false}
                                    taskTempStatus={360}
                                    coord={[]}
                                    switchImg={() => {}}
                                    loadImage={() => {}}
                                    hideUpload={true}
                                />
                                <button
                                    className={generateBtnClass}
                                    disabled={generateLoading || is3DLoading}
                                    onClick={() => handleGenerate3D()}
                                >
                                    {is3DLoading ? "Generating..." : "Generate 3D"}
                                </button>
                            </div>
                        ) : map3dData.length > 0 ? (
                            <div className="h-[780px] w-[590px]" ref={step3Ref}>
                                <div id="map" className={cx("relative overflow-hidden")}>
                                    <canvas
                                        id="mapgen4"
                                        width="2048"
                                        height="2048"
                                        className={cx({
                                            "!absolute bottom-full left-full": hideRedMap,
                                        })}
                                    />

                                    <img
                                        src={baseImg}
                                        className={cx("w-full", { "!hidden": !hideRedMap })}
                                    />
                                    <div
                                        className={"zoom-slider-container"}
                                        style={{
                                            position: "absolute",
                                            zIndex: 10,
                                            bottom: "10px",
                                            right: "10px",
                                            pointerEvents: "none", // 允许事件穿透
                                            backgroundColor: "transparent", // 确保背景透明
                                        }}
                                    >
                                        <input
                                            className="zoom-slider"
                                            type="range"
                                            name=""
                                            id="zoom-slider"
                                            max="5"
                                            min="0.5"
                                            step="0.1"
                                            style={{ pointerEvents: "auto" }} // 允许滑块本身接收事件
                                        />

                                        <div
                                            className={cx("zoom-size-display", {
                                                "red-text": showTips,
                                            })}
                                            style={{ pointerEvents: "auto" }}
                                        >
                                            size: 5 * 5 km
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("tips mt-4", { "tips-hidden": !showTips })}>
                                    The map size exceeds the 2.5km limit and cannot be generated
                                </div>
                                <MapgenTool
                                    mapgen={mapgen}
                                    mapgenSelect={mapgenSelect}
                                    setMapgenSelect={setMapgenSelect}
                                    onTownSceneChange={() => {}}
                                />
                                <div id="sliders" style={{ display: "none" }}>
                                    <button id="button-reset">Reset</button>
                                </div>
                                <button className={generateBtnClass} onClick={generateHandler}>
                                    Generate 3D preview
                                </button>
                            </div>
                        ) : (
                            <div className="flex h-[512px] w-[512px] items-center justify-center text-white opacity-20">
                                No 3D map data
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Warning
                isVisible={warningVisible}
                onCancel={handleWarningCancel}
                onContinue={() => handleWarningContinue()}
                text="Current operation will lose the generated image result"
            />
        </div>
    );
}

export default LabTag;
