// @ts-nocheck
import {BRUSH_SIZES_BY_ZOOM ,BRUSH_SIZES, TERRAIN_TOOLS} from "./const.ts"

// 类型定义
export type BrushSize = keyof typeof BRUSH_SIZES;
export type BrushTool = keyof typeof TERRAIN_TOOLS;

interface BrushStateChangeListener {
    onToolChange?: (tool: BrushTool) => void;
    onSizeChange?: (size: BrushSize) => void;
}
 class Brush {
    public currentTool: BrushTool;
    public currentSize: BrushSize;
    public currentRate:number;
    private listeners: BrushStateChangeListener[] = [];

    constructor(initialTool: BrushTool = 'mountain', initialSize: BrushSize = 'medium' as BrushSize) {
        this.currentTool = initialTool;
        this.currentSize = initialSize;
        this.currentRate = 4;
    }

    private preventTouchStart = (event: TouchEvent) => {
        event.preventDefault();
    }

    // 工具相关方法
    public setTool(tool: BrushTool): void {
        if (!TERRAIN_TOOLS[tool]) {
            console.warn(`Invalid tool: ${tool}`);
            return;
        }

        if (this.currentTool !== tool) {
            this.currentTool = tool;
            this.notifyToolChange();
        }
    }

    public getTool(): BrushTool {
        return this.currentTool;
    }

    public getToolElevation(): Record<BrushTool, number> {
        const elevations: Record<BrushTool, number> = {} as Record<BrushTool, number>;
        for (const tool in TERRAIN_TOOLS) {
            elevations[tool as BrushTool] = TERRAIN_TOOLS[tool as BrushTool].elevation;
        }
        return elevations;
    }

    public getToolConfig() {
        return  TERRAIN_TOOLS[this.currentTool]
    }

    // 尺寸相关方法
    public setSize(size: BrushSize): void {
        if (!BRUSH_SIZES[size]) {
            console.warn(`Invalid size: ${size}`);
            return;
        }

        if (this.currentSize !== size) {
            this.currentSize = size;
            this.notifySizeChange();
        }
    }

    public setRate(rate:number):void{
        this.currentRate = rate;
    }

    public getRate():number{
        return this.currentRate;
    }

    public getSize(): BrushSize {
        return this.currentSize;
    }

    public getSizeConfig() {
        return BRUSH_SIZES[this.currentSize];
    }

    public setSizeConfigByZoom(zoom:number):void{

        const currentSize = Object.values(BRUSH_SIZES_BY_ZOOM).find(
            ({ min, max }) => zoom >= min && zoom < max
        )?.size  as BrushSize ;
        this.setSize(currentSize);
    }

    public getSizeConfigByZoom(zoom:number):BrushSize{
        return  Object.values(BRUSH_SIZES_BY_ZOOM).find(
            ({ min, max }) => zoom >= min && zoom < max
        )?.size as BrushSize;
    }

    // 快捷键相关方法
    public handleKeyboardShortcut(key: string): boolean {
        // 处理画笔大小快捷键
        const sizeEntry = Object.entries(BRUSH_SIZES).find(([_, config]) => config.key === key);
        if (sizeEntry) {
            this.setSize(sizeEntry[0] as BrushSize);
            return true;
        }
        return false;
    }

    // 状态监听相关方法
    public addListener(listener: BrushStateChangeListener): void {
        this.listeners.push(listener);
    }

    public removeListener(listener: BrushStateChangeListener): void {
        const index = this.listeners.indexOf(listener);
        if (index > -1) {
            this.listeners.splice(index, 1);
        }
    }

    private notifyToolChange(): void {
        this.listeners.forEach(listener => {
            listener.onToolChange?.(this.currentTool);
        });
    }

    private notifySizeChange(): void {
        this.listeners.forEach(listener => {
            listener.onSizeChange?.(this.currentSize);
        });
    }

    // 工具状态检查方法
    public isValidTool(tool: string): tool is BrushTool {
        return tool in TERRAIN_TOOLS;
    }

    public isValidSize(size: string): size is BrushSize {
        return size in BRUSH_SIZES;
    }
    public isValidRate(rate:number):boolean{
        return rate > 1 && rate <= 9;
    }

    // 获取所有可用选项
    public static getAvailableTools(): BrushTool[] {
        return Object.keys(TERRAIN_TOOLS) as BrushTool[];
    }

    public static getAvailableSizes(): BrushSize[] {
        return Object.keys(BRUSH_SIZES) as BrushSize[];
    }

    public static getAvailableRates():number[]{
        return Array.from({length:9},(_,i)=>i+1);
    }

    // 计算画笔实际参数
    public getCurrentBrushParameters() {
        const sizeConfig = this.getSizeConfig();
        const toolConfig = this.getToolConfig();

        return {
            ...sizeConfig,
            ...toolConfig,
            tool: this.currentTool,
            size: this.currentSize
        };
    }
}


export default new Brush();