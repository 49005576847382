// hooks/useMapgenStorage.ts
import { useCallback } from "react";

import type { MapgenStorageData, MapInfo } from "../type";
import { getVillageFile } from "@/service/taskService";

export const useMapgenInfo = () => {
    // 从本地存储获取任务ID
    const getTaskIdFromLocal = useCallback((): string | null => {
        const taskId = localStorage.getItem("taskId");
        return taskId && taskId !== "undefined" ? taskId : null;
    }, []);

    // 从本地存储获取地图信息
    const getMapgenInfoFromLocal = useCallback((): MapgenStorageData | null => {
        const mapgenInfo = localStorage.getItem("mapgen_info");
        if (!mapgenInfo) return null;
        try {
            return JSON.parse(mapgenInfo);
        } catch (error) {
            console.error("Parse mapgen info failed:", error);
            return null;
        }
    }, []);

    // 保存地图信息到本地存储
    const saveMapgenInfo = useCallback((data: MapgenStorageData) => {
        try {
            localStorage.setItem("taskId", data.task_id);
            localStorage.setItem("mapgen_info", JSON.stringify(data));
        } catch (error) {
            console.error("Save mapgen info failed:", error);
        }
    }, []);

    // 清除本地存储
    const clearLocal = useCallback(() => {
        localStorage.removeItem("taskId");
        localStorage.removeItem("mapgen_info");
    }, []);

    const getMapInfo = async (taskId: string): Promise<MapInfo> => {
        const localData = localStorage.getItem("mapgen_info");
        if (localData) {
            const {
                size,
                data: constraints,
                terrain,
                sliders,
                position,
                seed,
            } = JSON.parse(localData);
            return { size, constraints, terrain, sliders, position, seed };
        }

        const res = await getVillageFile(taskId);
        if (res.status === 200) {
            const { map_size: size, constraints, terrain, sliders, position, seed } = res.data;
            return { size, constraints, terrain, sliders, position, seed };
        }
        throw new Error("Failed to get map info");
    };

    return {
        getTaskIdFromLocal,
        getMapgenInfoFromLocal,
        saveMapgenInfo,
        clearLocal,
        getMapInfo,
    };
};
