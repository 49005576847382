// @ts-nocheck
"use strict";

// import Renderer from "./core/render.ts";
import { buffer } from "stream/consumers";

import { makeMesh } from "./algorithm/mesh.ts";
import param from "./config/index.ts";
import { MAP_CENTER, ZOOM_DEFAULT } from "./const/common.ts";
import MapOperations from "./core/operations/index.ts";
import Painting from "./core/painting/index.ts";
import Renderer from "./core/render/index.ts";
import BasePlugin from "./plugin/base.ts";
import Sliders from "./plugin/sliders.ts";
// import MapWorker from "./service/index.ts";
import MapWorkerService from "./service/index.ts";
import { WorkerMessageType } from "./service/types.ts";
import type { Mesh } from "./types.d.ts";

import "./assets/css/common.css";

interface MapGenOptions {
    render?: Record<string, any>;
    painting?: Record<string, any>;
    operaters?: Record<string, any>;
    // constraints?: { [key: string]: number };
}

interface WorkerMessage {
    param: typeof param;
    constraints: {
        size: number;
        constraints: any;
    };
    buffers: any;
}
interface WorkerResponse {
    elapsed: number;
    numRiverTriangles: number;
    buffers: {
        quad_elements: Int32Array;
        a_quad_em: Float32Array;
        a_river_xyuv: Float32Array;
    };
}
interface RenderPayload {
    mesh: typeof this.mesh;
    t_peaks: typeof this.t_peaks;
    param: typeof param;
}

interface MeshData {
    mesh: any; // 根据实际类型定义
    t_peaks: any; // 根据实际类型定义
}

interface GenerateOptions {
    seed?: number;
    isForceUpdate?: boolean;
    constraints?: Record<number, number>;
}

class MapGen {
    public renderer: Renderer;
    public painting: any;
    protected mesh: Mesh;
    protected t_peaks: any;

    private el: string;
    protected plugins: Map<string, BasePlugin> = new Map();

    private isInitialized: boolean = false;
    private readyPromise: Promise<void>;
    private readyResolver!: () => void;
    // private zoom: number;
    private mapWorker: MapWorker;
    // private constraints: any;
    private mapOperations: MapOperations;
    private isEditMode: boolean = false;
    private cb: () => void;
    private mapSlider: HTMLInputElement;
    private zoom: number = ZOOM_DEFAULT;
    private constraints: Record<number, number> = {};

    constructor(el: string, options: MapGenOptions) {
        this.el = el;
        this.readyPromise = new Promise((resolve) => {
            this.readyResolver = resolve;
        });

        this.init(options);
        this.use("sliders", Sliders);
    }

    private async init(options: MapGenOptions): Promise<void> {
        try {
            const { initiate, render, painting, operaters } = options;

            // mesh data
            await this.initializeMeshData();
            // renderer
            this.initializeRenderer(render);
            // painting
            this.initializePainting(painting);
            // map operations
            // const { operaters } = options;
            this.initializeMapOperations(operaters);

            // basic config
            this.initializeBasicConfig(initiate);
            // worker
            this.initializeWorker();
            // plugins
            await this.initializePlugins();
            this.completeInitialization();
        } catch (error) {
            console.error("MapGen initialization failed:", error);
            throw error;
        }
    }
    // basic config
    private initializeBasicConfig(options: MapGenOptions): void {
        //设置ZOOM滑杆初始值
        const { position, size } = options;
        this.mapOperations.getZoom().setSliderValue(size / 1000);

        // if (position) {
        //     const { x, y } = position;
        //     this.renderer.setPosition(0, 0);
        // }
        // const { position, size } = options;
        // const { x, y } = position;
        // this.renderer.setPosition(-x, -y);
    }

    // mesh data
    private async initializeMeshData(): Promise<void> {
        const { mesh, t_peaks } = await makeMesh();
        this.mesh = mesh;
        this.t_peaks = t_peaks;
    }

    private initializePainting(config: Record<string, any>): void {
        this.painting = new Painting(`${this.el}`, "map", config, {
            render: this.renderer,
            plugins: this.plugins,
            setEditMode: this.setEditMode.bind(this),
            isEdit: this.isEdit.bind(this),
            generate: this.generate.bind(this),
            addVillageIcon: this.renderer.addVillageIcon.bind(this.renderer),
            deleteVillageIcon: this.renderer.deleteVillageIcon.bind(this.renderer),
            screenToWorld: this.renderer.screenToWorld.bind(this.renderer),
        });
    }

    private initializeMapOperations(operaters: Record<string, any>): void {
        this.mapOperations = new MapOperations(
            document.querySelector(`#${this.el}`) as HTMLElement,
            operaters,
            this,
        );

        this.mapSlider = this.mapOperations.getZoom().getSliderElement();

        this.mapOperations.getZoom().onWheeling((zoom, x, y) => {
            requestAnimationFrame(() => {
                this.renderer.setZoom(zoom);
                if (zoom <= ZOOM_DEFAULT) {
                    this.renderer.setPosition(-MAP_CENTER, -MAP_CENTER);
                } else {
                    if (x && y) {
                        this.renderer.setPosition(x, y);
                    }
                }
                const brushSize: BrushSize = this.painting.getBrush().getSizeConfigByZoom(zoom);
                this.painting.getBrush().setSize(brushSize);
            });
        });
        this.mapOperations.getDrag().onMouseMove((x, y) => {
            this.renderer.setPosition(x, y);
        });
    }

    // renderer
    private initializeRenderer(config: Record<string, any>): void {
        if (!this.el) {
            throw new Error("element container is required");
        }
        if (!this.mesh) {
            throw new Error("Mesh data not initialized");
        }
        this.zoom = config.zoom || ZOOM_DEFAULT;
        this.renderer = new Renderer(this.el, this.mesh, config, {
            plugins: this.plugins,
            generate: this.generate.bind(this),
            setEditMode: this.setEditMode.bind(this),
        });
    }

    private initializeWorker(): void {
        if (!this.renderer) {
            throw new Error("Renderer not initialized");
        }
        this.mapWorker = new MapWorkerService(this.renderer, this.generate.bind(this));
        this.mapWorker.createMap();
    }

    private async initializePlugins(): Promise<void> {
        if (this.plugins.size > 0) {
            await Promise.all(
                Array.from(this.plugins.values()).map((plugin) => plugin.onInit?.(this)),
            );
        }
    }

    private completeInitialization(): void {
        this.isInitialized = true;
        this.readyResolver();
    }

    private use(name: string, plugin) {
        plugin.init?.(this);
        this.plugins.set(name, plugin);
    }

    public getMapSlider() {
        return this.mapSlider;
    }

    public clearConstraints() {
        this.constraints = {};
    }

    public generate(options?: GenerateOptions): void {
        if (!this.mapWorker.isWorking()) {
            let { seed, isForceUpdate, constraints } = options || {};

            this.constraints = constraints ?? this.constraints;
            const needForceUpdate = isForceUpdate || Object.keys(this.constraints).length === 0;

            if (seed) {
                param.elevation.seed = seed;
                this.painting.generateElevation(param.elevation);
            }

            if (needForceUpdate) {
                this.painting.generateElevation(param.elevation);
            } else {
                if (Object.values(this.constraints).some((value) => value !== 0)) {
                    this.painting.setElevation(this.constraints);
                }
            }

            this.mapWorker.startWork();
            const payload: WorkerMessage = {
                param,
                constraints: {
                    size: this.painting.CANVAS_SIZE,
                    constraints: this.painting.getElevation(),
                },
                ...this.renderer.getTransferBuffers(),
            };
            const transferBuffers = this.renderer.getTransferBufferList();

            this.mapWorker.postMessage(WorkerMessageType.GENERATE, payload, transferBuffers);
        } else {
            this.mapWorker.requestWork();
        }
    }

    public async onReady(): Promise<void> {
        await this.readyPromise;
    }

    public start(options?: { seed?: number; constraints?: Record<number, number> }): void {
        if (!this.isInitialized) {
            console.warn("MapGen is not ready for Render");
            return null;
        }

        if (!this._validateRenderParams()) {
            console.error("Invalid render parameters");
            return;
        }

        try {
            // prepare render data
            const payload: RenderPayload = {
                mesh: this.mesh,
                t_peaks: this.t_peaks,
                param,
            };
            this.constraints = options?.constraints ?? this.constraints;
            // send render message
            if (this.mapWorker?.getWorker()) {
                this.mapWorker.postMessage(WorkerMessageType.INIT, payload);
                this.generate({ ...options });
                this.renderer.setZoom(ZOOM_DEFAULT);
            } else {
                throw new Error("Worker not available");
            }
        } catch (error) {
            console.error("Render failed:", error);
        }
    }
    public reload(): void {
        const seed = Math.floor(Math.random() * (1 << 30));
        this.painting.resetPaintingElevation();
        this.generate({ seed });
    }

    private _validateRenderParams(): boolean {
        return !!(this.mesh && this.t_peaks && this.mapWorker?.getWorker());
    }

    public getRender(): Renderer {
        return this.renderer;
    }

    public getPainting(): Painting {
        return this.painting;
    }

    public getMapOperations() {
        return this.mapOperations;
    }

    public getPlugins() {
        return this.plugins;
    }

    public setEditMode(editMode: boolean) {
        this.isEditMode = editMode;
    }

    public isEdit(): boolean {
        return this.isEditMode;
    }

    public setZoom(zoom: number) {
        this.zoom = zoom;
        this.renderer.setZoom(zoom);
    }

    public dispose(): void {
        try {
            // dispose worker
            if (this.mapWorker) {
                this.mapWorker.dispose();
            }
            // dispose renderer
            if (this.renderer) {
                this.renderer.dispose();
            }

            if (this.painting) {
                this.painting.dispose();
            }

            if (this.mapOperations) {
                this.mapOperations.dispose();
            }

            this.plugins.forEach((plugin) => plugin.onDestroy?.(this));
            this.plugins.clear();
        } catch (error) {
            console.error("Error disposing MapGen:", error);
        }
    }
}

export default MapGen;
