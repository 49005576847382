import { useState } from 'react';
import Pagination from '../Pagination/Pagination';
import './index.css';

export interface Media {
    type: string;
    url: string;
}

export interface MapOption {
    id: string;
    cover: string;
    title: string;
    index: number;
    media: Media[];
}

interface MapSelectorProps {
    options: MapOption[];
    value?: string; // 当前选中项的 id
    onChange?: (id: string, cover: string, text: string, media: Media[], index: number) => void;
    title: string;
}

function MapSelector({ options, value, title, onChange }: MapSelectorProps) {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const pageSize = 9;
    const isNeedPagination = 12;

    const filteredOptions = options.filter((option) =>
        option.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const needPagination = filteredOptions.length >= isNeedPagination;

    const currentOptions = needPagination
        ? filteredOptions.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        : filteredOptions;

    return (
        <div className="random w-[520px] rounded-md p-5">
            <div className="mb-3 flex items-center justify-between">
                <span>{title}</span>
                <div className="flex w-[280px] items-center rounded-full border border-white/30">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-3 mr-2 h-4 w-4 text-white/60"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                    <input
                        type="text"
                        placeholder="Search"
                        className="h-[26px] w-[220px] bg-transparent text-sm outline-none"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            setCurrentPage(1);
                        }}
                    />
                    {searchQuery && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-3 h-4 w-4 text-white/60 cursor-pointer hover:text-white/90"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            onClick={() => {
                                setSearchQuery('');
                                setCurrentPage(1);
                            }}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    )}
                </div>
            </div>
            <div className="flex flex-wrap gap-4">
                {currentOptions.map((option) => (
                    <div
                        key={option.id}
                        className={`cursor-pointer p-2 rounded-lg bg-white/10 transition-all hover:border-white/30 ${
                            value === option.id
                                ? 'border-2 border-white/70'
                                : 'border-2 border-transparent'
                        }`}
                        onClick={() => onChange?.(option.id, option.cover, option.title, option.media, option.index)}
                    >
                        <img
                            src={option.cover}
                            alt={option.title}
                            className="h-16 w-32 object-cover"
                        />
                        <p
                            className="mt-2 max-w-[128px] overflow-hidden truncate text-center text-sm"
                            title={option.title}
                        >
                            {option.title}
                        </p>
                    </div>
                ))}
            </div>

            {needPagination && (
                <div className="mt-4 flex justify-start">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={Math.ceil(filteredOptions.length / pageSize)}
                        onPageChange={(page) => {
                            setCurrentPage(page);
                            const firstOption = filteredOptions[(page - 1) * pageSize];
                            if (firstOption) {
                                onChange?.(
                                    firstOption.id,
                                    firstOption.cover,
                                    firstOption.title,
                                    firstOption.media,
                                    firstOption.index,
                                );
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default MapSelector;
