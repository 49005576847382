import { useEffect, useRef, useState } from "react";
import { AxiosError } from "axios";
import { downloadCheck } from "@/lib/utils";
import { Link, useParams, useSearchParams } from "react-router-dom";

import UESceneHeader from "@/components/UESceneHeader";
import UEPreview from "@/components/UEPreview";
import { closeTask, taskRender, taskStatus } from "@/service/taskService";
import { message, Modal } from "antd";
import backIcon from "@/assets/back.png";
import { UeplayerRefProps } from "@/types";

function ShowCaseUEpreview() {
    const [searchParams, _] = useSearchParams();
    const scene = searchParams.get("scene");
    const ueplayerRef = useRef<UeplayerRefProps>(null);
    const [isUeFullScreen, setIsUeFullScreen] = useState<boolean>(false);
    const fullScreenBtnRef = useRef<HTMLButtonElement>(null);
    const [streamID, setStreamID] = useState<string>("");
    const [currentTaskStatus, setCurrentTaskStatus] = useState<number>(0);
    const { task_id } = useParams<{ task_id: string }>();
    if (!task_id) {
        return <div>No task id</div>;
    }
    useEffect(() => {
        taskView();
    }, []);

    // 检查任务的状态
    const taskView = async () => {
        console.log("task_id:", task_id);
        var response, status, stream_id;
        try {
            response = await taskStatus(task_id as string);
            console.log({ streamID: response.data.ue_worker_id });
            console.log({ response });
            // if(response.data.status === "900") {
            //     let res = await taskRender(task_id);
            //     status = res.data.status;
            // }
            stream_id = response.data.ue_worker_id;
            status = Number(response?.data?.status);
            if (!status) {
                message.error("Invalid task status");
                return;
            }
        } catch (error) {
            message.error("Error in querying task status!");
            return;
        }

        if (status === 360) {
            stream_id && setStreamID(stream_id);
        }
        setCurrentTaskStatus(status);
    };

    // UE生成完成
    const ueComplete = (isSuccess: boolean, status: number, streamID: string) => {
        console.log("ueComplete: ", { isSuccess, status, streamID });
        console.log({ streamID });
        if (status === 500) {
            message.error("Failed to generate UE scene.");
            return;
        }

        if (isSuccess) {
            streamID && setStreamID(streamID);
        }
        setCurrentTaskStatus(status);
    };

    // 快速生成UE场景
    const quickGenerateUEScene = async () => {
        try {
            await taskRender(task_id);
            setCurrentTaskStatus(260);
        } catch (error) {
            message.error("Error in generating UE scene!");
        }
    };

    // 处理连接错误
    const handleConnectionError = async (status: number) => {
        console.log({ status });
        setStreamID("");
        setCurrentTaskStatus(900);
        if (status === 0) {
            Modal.confirm({
                title: "Tips",
                content: `You have been inactive for a while, are you still there? 
            The 3D environment will automatically close to free up resources.`,
                okText: "I am here",
                cancelText: "Close task",
                onOk: connect,
                onCancel: closeWS,
            });
        } else if (status === 1) {
            message.warning(`Your network speed is slow. Please change the network.`);
        } else if (status === 2) {
            Modal.confirm({
                title: "Tips",
                content: `Your network is disconnected, please check your network connection`,
                cancelButtonProps: { style: { display: "none" } },
                onOk: () => { },
            });
        } else if (status === 3) {
            Modal.confirm({
                title: "Tips",
                content: `Server is busy, please try again later.`,
                cancelButtonProps: { style: { display: "none" } },
                onOk: () => { },
            });
        }
    };

    // 连接UE
    const connect = async () => {
        setStreamID("");
        var response, stream_id;
        try {
            response = await taskStatus(task_id);
            stream_id = response.data.ue_worker_id;
        } catch (error) {
            // @ts-ignore
            console.log("error:", error.message);
            await closeTask(task_id);
            Modal.confirm({
                title: "error",
                content: `Network error, please switch to a different network.`,
                cancelButtonProps: { style: { display: "none" } },
                onOk: () => { },
            });
        }
        console.log({ response });
        console.log({ streamID: response?.data.ue_worker_id });
        if (response?.data.status === "360") {
            setCurrentTaskStatus(360);
            stream_id && setStreamID(stream_id);
        }

        if (response?.data.status === "900") {
            quickGenerateUEScene();
        }
    };

    // 关闭UE连接
    const closeWS = async () => {
        try {
            if (currentTaskStatus >= 360) {
                var response = await closeTask(task_id);
                console.log("关闭状态：", response.status);
                setCurrentTaskStatus(1000);
                setStreamID("");
                setTimeout(() => {
                    setCurrentTaskStatus(900);
                }, 3000);
            } else {
                message.error("Failed to close the UE scene.");
            }
        } catch (error) {
            message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
        }
    };


    const ueContainerClasses = `text-white h-full text-sm flex flex-col 
    ${isUeFullScreen ? " fixed top-0 left-0 w-full bg-[#191919]" : ""}`;

    return (
        <div className="flex flex-col h-full">
            <div className=" flex items-center mb-4">
                <Link to="/labs/cases" className="flex items-center cursor-pointer">
                    <img src={backIcon} alt="back" width={14} height={14} />
                    <span className="ml-2 text-link">Back</span>
                </Link>
                <h2 className="ml-5">{scene}</h2>
            </div>
            <div className="flex-1 w-full rounded-md bg-[#191919] text-[#5E5E5E] overflow-hidden">
                <div className={ueContainerClasses}>
                    <UESceneHeader
                        isUeFullScreen={isUeFullScreen}
                        setIsUeFullScreen={setIsUeFullScreen}
                        closeWS={closeWS}
                        taskid={task_id}
                        downloadCheck={downloadCheck}
                        fullScreenBtnRef={fullScreenBtnRef}
                        isShowDownBtn={false}
                        downLoadImgHandler={() => {
                            const ueplayer = ueplayerRef.current?.getUeplayerRef();
                            if (ueplayer) {
                                ueplayer.sendScreenshot();
                            }
                        }}
                    />
                    <div className="flex-1">
                        <UEPreview
                            taskId={task_id}
                            status={currentTaskStatus}
                            ref={ueplayerRef}
                            onComplete={(
                                isSuccess: boolean,
                                status: number,
                                streamID: string
                            ) => {
                                ueComplete(isSuccess, status, streamID);
                            }}
                            startTime={0}
                            reconnect={(status: number) => {
                                handleConnectionError(status);
                            }}
                            quickGenerate={() => {
                                quickGenerateUEScene();
                            }}
                            streamID={streamID}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowCaseUEpreview;