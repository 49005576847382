import { useEffect, useState } from "react";

interface ImageCarouselProps {
    images: { url: string }[];
}

const ImageCarousel = ({ images }: ImageCarouselProps) => {
    console.log({ images });
    const [currentIndex, setCurrentIndex] = useState(1);
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        setCurrentIndex(1);
        setIsTransitioning(false);
    }, [images.length]);

    const handleTransitionEnd = () => {
        if (currentIndex === 0) {
            setIsTransitioning(false);
            setCurrentIndex(images.length);
        } else if (currentIndex === images.length + 1) {
            setIsTransitioning(false);
            setCurrentIndex(1);
        } else {
            setIsTransitioning(false);
        }
    };

    const handlePrevious = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setCurrentIndex((prev) => prev - 1);
    };

    const handleNext = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setCurrentIndex((prev) => prev + 1);
    };

    const goToSlide = (index: number) => {
        if (isTransitioning || index < 0 || index >= images.length) return;
        setIsTransitioning(true);
        setCurrentIndex(index + 1);
    };

    if (!images || images.length === 0) {
        return <div className="text-white opacity-20">No image</div>;
    }

    const extendedImages = [images[images.length - 1], ...images, images[0]];

    return (
        <div className="relative h-full w-full overflow-hidden">
            <div
                className="flex h-full w-full"
                style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                    transition: isTransitioning ? "transform 300ms ease-in-out" : "none",
                }}
                onTransitionEnd={handleTransitionEnd}
            >
                {extendedImages.map((image, index) => (
                    <img
                        key={index}
                        src={image.url}
                        alt={`slide-${index}`}
                        className="h-auto w-full flex-shrink-0 object-contain"
                    />
                ))}
            </div>

            {images.length > 1 && (
                <>
                    <button
                        onClick={handlePrevious}
                        className="absolute left-4 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-full bg-black/50 transition-colors hover:bg-black/70"
                        aria-label="Previous image"
                    >
                        <svg
                            className="h-6 w-6 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </button>

                    <button
                        onClick={handleNext}
                        className="absolute right-4 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-full bg-black/50 transition-colors hover:bg-black/70"
                        aria-label="Next image"
                    >
                        <svg
                            className="h-6 w-6 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                    </button>

                    <div className="absolute bottom-4 left-1/2 flex -translate-x-1/2 transform gap-2">
                        {images.map((_, index) => (
                            <button
                                key={index}
                                className={`h-2 w-2 rounded-full transition-colors ${
                                    index === (currentIndex - 1 + images.length) % images.length
                                        ? "bg-white"
                                        : "bg-gray-500"
                                }`}
                                onClick={() => goToSlide(index)}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default ImageCarousel;
