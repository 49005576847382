export const useHideUnity = () => {
    const showUnityTags = [
        "localhost",
        "https://stage.cybever.ai",
        "https://auto-3d-qa.cybever.ai",
        "https://auto-3d-dev.cybever.ai"
    ];

    const hideUnity = !showUnityTags.find((tag) => window.location.origin.includes(tag));

    return hideUnity;
};
