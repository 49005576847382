import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';

import Home from "./pages/Home/Home.tsx";
import AssetsLibrary from "./pages/AssetsLibrary/AssetsLibrary.tsx";
import Collections from "./pages/Collections/Collections.tsx";
import Marketplace from "./pages/Marketplace/Marketplace.tsx";
import MyOrders from "./pages/MyOrders/MyOrders.tsx";
import MyScenes from './pages/MyScenes/MyScenes.tsx';
import Agreement from "./pages/UserAgreement/Agreement.tsx";
import Login from './pages/Login/Login.tsx';
import Upload from './pages/Upload/Upload.tsx';
import GenerationMethod from './pages/GenerationMethod/GenerationMethod.tsx';
import Mapgen from './pages/Mapgen/Mapgen.tsx';
import Preview from './pages/Preview/Preview.tsx';
import NotFound from './components/NotFound/index.tsx';

import type { RootState } from "./store/store.ts";
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import PrivateRoute from './components/PrivateRoute.tsx';
import { useEffect } from "react";
import WaitList from './pages/WaitList/WaitList.tsx';
import Labs from './pages/Labs/Labs.tsx';
import LabTag from './pages/LabTag/LabTag.tsx';
import ShowCases from './pages/ShowCases/ShowCases.tsx';
import Mask from './pages/Mask/Mask.tsx';
import ShowCaseUEpreview from './pages/ShowCaseUEpreview/ShowCaseUEpreview.tsx';
import VillageLayout from './pages/LabTagToVillageLayout/index.tsx';
import LayoutRender from './components/LayoutRender/index.tsx';
import LabMapgen from './pages/LabText/Mapgen.tsx';
import LabTagToVillageLayout from './pages/LabTextLayout/index.tsx';
import Download from './pages/Download/index.tsx';
import LabColorMap from './pages/LabColorMap/LabColorMap.tsx';

export default function App() {
  const token = useSelector((state: RootState) => state.user.token);

  useEffect(() => {
    ReactGA.initialize(import.meta.env.VITE_APP_TRACKING_ID);
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<LayoutRender />}>
        <Route
            path="/"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/home/scene/choice"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <GenerationMethod />
              </PrivateRoute>
            }
          />
          <Route
            path="/home/create3d"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Mapgen />
              </PrivateRoute>
            }
          />
          <Route
            path="/home/preview"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Preview />
              </PrivateRoute>
            }
          />
          <Route
            path="/labs/cases"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <ShowCases />
              </PrivateRoute>
            }
          />
          <Route
            path="/download"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Download />
              </PrivateRoute>
            }
          />
          <Route
            path="/labs"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Labs />
              </PrivateRoute>
            }
          />
          <Route
            path="/labs/colormap"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <LabColorMap />
              </PrivateRoute>
            }
          />
          <Route
            path="/labs/tag"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <LabTag />
              </PrivateRoute>
            }
          />
          <Route
            path="/labs/create3d"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <LabMapgen />
              </PrivateRoute>
            }
          />
          <Route
            path="/labs/editor"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Labs />
              </PrivateRoute>
            }
          />
          <Route
            path="/labs/mask"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Mask />
              </PrivateRoute>
            }
          />
          <Route
            path="/labs/village"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <VillageLayout />
              </PrivateRoute>
            }
          />
          <Route
            path="/labs/textlayout"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <LabTagToVillageLayout />
              </PrivateRoute>
            }
          />
          <Route path="/assetslibrary" element={<AssetsLibrary />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/myorders" element={<MyOrders />} />
          <Route path="/myscenes" element={<MyScenes />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/login" element={<Login />} />
          <Route path="/wait" element={<WaitList />} />
          <Route path="/uepreview/:task_id" element={<ShowCaseUEpreview />} />
          <Route path="*" element={<NotFound />} />
      </Route>,
    ),
  );


  return (
    <RouterProvider router={router} />
  );
}
