import scene1Cover from "@/assets/mapgen/scene1-cover.png";
import scene1Media1 from "@/assets/mapgen/scene1-media1.png";
import scene1Media2 from "@/assets/mapgen/scene1-media2.png";
import scene1Media3 from "@/assets/mapgen/scene1-media3.png";
import scene1Media4 from "@/assets/mapgen/scene1-media4.png";
import scene1Media5 from "@/assets/mapgen/scene1-media5.png";
import scene2Cover from "@/assets/mapgen/scene2-cover.png";
import scene2Media1 from "@/assets/mapgen/scene2-media1.png";
import scene2Media2 from "@/assets/mapgen/scene2-media2.png";
import scene2Media3 from "@/assets/mapgen/scene2-media3.png";
import scene2Media4 from "@/assets/mapgen/scene2-media4.png";
import scene2Media5 from "@/assets/mapgen/scene2-media5.png";
import scene3Cover from "@/assets/mapgen/scene3-cover.png";
import scene3Media1 from "@/assets/mapgen/scene3-media1.png";
import scene3Media2 from "@/assets/mapgen/scene3-media2.png";
import scene3Media3 from "@/assets/mapgen/scene3-media3.png";
import scene3Media4 from "@/assets/mapgen/scene3-media4.png";
import scene3Media5 from "@/assets/mapgen/scene3-media5.png";

export const SCENE_LIST = [
    {
        cover: scene1Cover,
        id: "12",
        index: "1",
        media: [
            {
                type: "image",
                url: scene1Media1,
            },
            {
                type: "image",
                url: scene1Media2,
            },
            {
                type: "image",
                url: scene1Media3,
            },
            {
                type: "image",
                url: scene1Media4,
            },
            {
                type: "image",
                url: scene1Media5,
            },
        ],
        title: "Willowstead",
    },
    {
        cover: scene2Cover,
        id: "21",
        index: "4",
        media: [
            {
                type: "image",
                url: scene2Media1,
            },
            {
                type: "image",
                url: scene2Media2,
            },
            {
                type: "image",
                url: scene2Media3,
            },
            {
                type: "image",
                url: scene2Media4,
            },
            {
                type: "image",
                url: scene2Media5,
            },
        ],
        title: "Golden Oasis  ",
    },
    {
        cover: scene3Cover,
        id: "9",
        index: "3",
        media: [
            {
                type: "image",
                url: scene3Media1,
            },
            {
                type: "image",
                url: scene3Media2,
            },
            {
                type: "image",
                url: scene3Media3,
            },
            {
                type: "image",
                url: scene3Media4,
            },
            {
                type: "image",
                url: scene3Media5,
            },
        ],
        title: "Meadowstead",
    },
];
