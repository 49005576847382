interface TagOption {
    value: string | number;
    label: string;
}

interface TagDropDownProps {
    options: TagOption[];
    onChange: (value: string | number) => void;
    value?: string | number;
    isDisable?: boolean; 
}

const tagDropDownStyle = `bg-transparent border border-white/20 outline-none text-sm
rounded-md px-2 py-1 focus:outline-none focus:ring-0 focus:border-white/20 [&>option]:bg-black`;

function TagDropDown({ options, onChange, value, isDisable }: TagDropDownProps) {
    return (
        <select 
            onChange={(e) => onChange(e.target.value)}
            value={value}
            className={`${tagDropDownStyle} ${isDisable ? 'cursor-not-allowed opacity-20' : ''}`}
            disabled={isDisable}
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
}

export default TagDropDown;